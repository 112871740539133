import React from 'react';
import { AuthProvider } from '@teamexos/fit-shared';

import { SnackbarContextProvider } from 'contexts/snackbarContext';

import useMixpanel from 'hooks/useMixpanel';
import { MixpanelProvider } from '../utils/mixpanel';
import PrinceSDKProvider from '../contexts/princeSDKContext';
import ThemeProviders from './ThemeProviders';

const sharedPlatformDomain = process.env.REACT_APP_PRINCE_GQL_URL;

if (!sharedPlatformDomain) {
  throw new Error('REACT_APP_PRINCE_GQL_URL is not defined');
}

const Providers: React.FC<React.PropsWithChildren> = ({ children }) => (
  <AuthProvider
    sharedPlatformDomain={sharedPlatformDomain}
    trackHook={useMixpanel}
    localSignOut={async () => {}}
  >
    <PrinceSDKProvider>
      <ThemeProviders>
        <SnackbarContextProvider>
          <MixpanelProvider token={process.env.REACT_APP_MIXPANEL_TOKEN}>
            {children}
          </MixpanelProvider>
        </SnackbarContextProvider>
      </ThemeProviders>
    </PrinceSDKProvider>
  </AuthProvider>
);

export default Providers;
