import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Duration: { input: any; output: any };
  JSONObject: {
    input: Record<string, unknown>;
    output: Record<string, unknown>;
  };
  LocalDate: { input: string; output: string };
  LocalDateTime: { input: string; output: string };
  LocalTime: { input: string; output: string };
  ZoneId: { input: string; output: string };
  ZonedDateTime: { input: string; output: string };
};

export type ActiveCustomPlanDay = {
  __typename?: 'ActiveCustomPlanDay';
  date: Scalars['LocalDate']['output'];
  itemCompletedCount: Scalars['Int']['output'];
  itemCount: Scalars['Int']['output'];
};

export type Activity = {
  date: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  program?: Maybe<Program>;
  progress: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  trendType?: Maybe<ActivityTrendType>;
  type: ActivityType;
};

export type ActivityAggregates = {
  __typename?: 'ActivityAggregates';
  /** count of activities completed excluding sleep */
  activities: Scalars['Int']['output'];
  /** selected date range */
  date?: Maybe<ZonedDateTimeRange>;
  /** count of days that had at least 1 activity */
  days: Scalars['Int']['output'];
  /** count of real activity minutes excluding sleep */
  minutes: Scalars['Int']['output'];
  minutesAsleepPerDayAverage: Scalars['Int']['output'];
  restingHeartRatePerDayAverage: Scalars['Int']['output'];
};

export type ActivityAttribution = AssignedPractice | AssignedProgram;

export type ActivityConnection = Connection & {
  __typename?: 'ActivityConnection';
  count: Scalars['Int']['output'];
  edges: Array<ActivityEdge>;
  nodes: Array<Activity>;
  pageInfo: PageInfo;
};

export type ActivityEdge = Edge & {
  __typename?: 'ActivityEdge';
  cursor: Scalars['String']['output'];
  node: Activity;
};

export type ActivityFilter = {
  includeIncomplete?: InputMaybe<Scalars['Boolean']['input']>;
  trendType?: InputMaybe<Array<InputMaybe<ActivityTrendType>>>;
  type?: InputMaybe<Array<InputMaybe<ActivityType>>>;
};

export type ActivityGenre = {
  __typename?: 'ActivityGenre';
  canBeAddedManually: Scalars['Boolean']['output'];
  canHaveDistance: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ActivityGenresFilter = {
  canBeAddedManually?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityPayload = {
  __typename?: 'ActivityPayload';
  activity: Activity;
  user: User;
};

export type ActivitySource =
  | Article
  | Audio
  | MboClass
  | PhysicalActivity
  | Survey
  | Video
  | WearableActivity;

export enum ActivityTrendType {
  Classes = 'classes',
  ManualEntry = 'manual_entry',
  Practice = 'practice',
  Program = 'program',
  Sleep = 'sleep',
  /** @deprecated training plans have been retired */
  TrainingPlan = 'training_plan',
  Wearables = 'wearables',
}

export enum ActivityType {
  Aod = 'Aod',
  Article = 'Article',
  Encore = 'Encore',
  InPerson = 'InPerson',
  ManualEntry = 'ManualEntry',
  Virtual = 'Virtual',
  Vod = 'Vod',
  /** @deprecated use wearable sdk specific type */
  Wearable = 'Wearable',
  WearableGoogleFit = 'WearableGoogleFit',
  WearableHealthConnectActivity = 'WearableHealthConnectActivity',
  WearableHealthConnectSleep = 'WearableHealthConnectSleep',
  WearableHealthKit = 'WearableHealthKit',
  WearableHealthKitSleep = 'WearableHealthKitSleep',
}

export type AddPerformanceQualityScoresPayload = {
  __typename?: 'AddPerformanceQualityScoresPayload';
  performanceQualityScores: Array<PerformanceQualityScore>;
};

export type AddReactionInput = {
  kind: ReactionKind;
  messageId: Scalars['ID']['input'];
};

export type AddUserToStudioPayload = {
  __typename?: 'AddUserToStudioPayload';
  studio: Studio;
  user: User;
};

export type Address = {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressLine3?: Maybe<Scalars['String']['output']>;
  addressLine4?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  localityOrCity?: Maybe<Scalars['String']['output']>;
  postalOrZipCode?: Maybe<Scalars['String']['output']>;
  stateOrDistrictOrProvince?: Maybe<Scalars['String']['output']>;
};

export type AdminBackfillAssessmentAttributionTypeInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminChangeAuthProviderInput = {
  currentEmail: Scalars['String']['input'];
  idpUsername: Scalars['String']['input'];
  provider?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateUserInput = {
  createdAt?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  id: Scalars['ID']['input'];
  setTestPassword?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Answer = EnumAnswer | NumberAnswer | PhotoAnswer | StringAnswer;

export type AnswerSurveyInput = {
  answers: Array<SurveyResponseInput>;
  assessmentId?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  surveyIdentifier: Scalars['String']['input'];
  surveyName?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum App {
  Heart = 'heart',
  Prince = 'prince',
}

export type Article = Content &
  Node & {
    __typename?: 'Article';
    author?: Maybe<Scalars['String']['output']>;
    brand?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    /** @deprecated use contentEquipment */
    equipment: Array<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    gameplanMetadata?: Maybe<GameplanMetadata>;
    heroPhoto?: Maybe<Photo>;
    html?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    intensity?: Maybe<Scalars['String']['output']>;
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    mediaType?: Maybe<Scalars['String']['output']>;
    mnmrType?: Maybe<Scalars['String']['output']>;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    split?: Maybe<Split>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type ArticleRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ArticleActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'ArticleActivity';
    brand?: Maybe<Scalars['String']['output']>;
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']['output']>;
    coachLastName?: Maybe<Scalars['String']['output']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<Article>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type ArticlesConnection = Connection & {
  __typename?: 'ArticlesConnection';
  count: Scalars['Int']['output'];
  edges: Array<ArticlesEdge>;
  nodes: Array<Article>;
  pageInfo: PageInfo;
};

export type ArticlesEdge = Edge & {
  __typename?: 'ArticlesEdge';
  cursor: Scalars['String']['output'];
  node: Article;
};

export type AssessmentAssignment = Node & {
  __typename?: 'AssessmentAssignment';
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  survey?: Maybe<Survey>;
  surveyContent: InAppSurvey;
  template: SurveyTemplate;
};

export type AssessmentSurvey = Node & {
  __typename?: 'AssessmentSurvey';
  id: Scalars['ID']['output'];
  /**
   * whether or not this survey is shown to members
   * these are not directly assignable and are completed by coaches
   * @deprecated Use type instead
   */
  isMemberFacing: Scalars['Boolean']['output'];
  mnmrType?: Maybe<Scalars['String']['output']>;
  template: SurveyTemplate;
  title?: Maybe<Scalars['String']['output']>;
  type: AssessmentType;
};

export type AssessmentSurveyEdge = Edge & {
  __typename?: 'AssessmentSurveyEdge';
  cursor: Scalars['String']['output'];
  node: AssessmentSurvey;
};

export type AssessmentSurveysConnection = Connection & {
  __typename?: 'AssessmentSurveysConnection';
  count: Scalars['Int']['output'];
  edges: Array<AssessmentSurveyEdge>;
  nodes: Array<AssessmentSurvey>;
  pageInfo: PageInfo;
};

export enum AssessmentType {
  Coach = 'Coach',
  Member = 'Member',
  NutritionSpecialist = 'NutritionSpecialist',
}

export type AssignAssessmentsInput = {
  createdAt?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  /** If true, will allow admin users in the dev environment to override assignment date */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  memberId: Scalars['ID']['input'];
  surveyContentIds: Array<Scalars['ID']['input']>;
};

export type AssignAssessmentsPayload = {
  __typename?: 'AssignAssessmentsPayload';
  assessments: Array<AssessmentAssignment>;
};

export type AssignFitCoachPayload = {
  __typename?: 'AssignFitCoachPayload';
  coach: User;
  user: User;
};

export type AssignPracticePayload = {
  __typename?: 'AssignPracticePayload';
  assignedPractice: AssignedPractice;
};

export type AssignProgramPayload = {
  __typename?: 'AssignProgramPayload';
  assignedProgram: AssignedProgram;
};

export type AssignedPractice = {
  __typename?: 'AssignedPractice';
  assigningCoach?: Maybe<User>;
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  completionNotified: Scalars['Boolean']['output'];
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  isUnenrolled: Scalars['Boolean']['output'];
  practiceProgram?: Maybe<PracticeProgram>;
  user: User;
};

export type AssignedProgram = Node & {
  __typename?: 'AssignedProgram';
  assigningCoach?: Maybe<User>;
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  isUnenrolled: Scalars['Boolean']['output'];
  program?: Maybe<Program>;
  user: User;
};

export type Audio = Content &
  Node & {
    __typename?: 'Audio';
    audio: Scalars['String']['output'];
    author?: Maybe<Scalars['String']['output']>;
    brand?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    /** @deprecated use contentEquipment */
    equipment: Array<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    gameplanMetadata?: Maybe<GameplanMetadata>;
    heroPhoto?: Maybe<Photo>;
    html?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    intensity?: Maybe<Scalars['String']['output']>;
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    split?: Maybe<Split>;
    subGenre?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
    transcript?: Maybe<Scalars['String']['output']>;
    type?: Maybe<Scalars['String']['output']>;
  };

export type AudioRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type AudioActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'AudioActivity';
    brand?: Maybe<Scalars['String']['output']>;
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']['output']>;
    coachLastName?: Maybe<Scalars['String']['output']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<Audio>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type AuditLog = Node & {
  __typename?: 'AuditLog';
  action: Scalars['String']['output'];
  createdAt: Scalars['ZonedDateTime']['output'];
  data?: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['ID']['output'];
  principal: Principal;
};

export type AuditLogEdge = Edge & {
  __typename?: 'AuditLogEdge';
  cursor: Scalars['String']['output'];
  node: AuditLog;
};

export type AuditLogsConnection = Connection & {
  __typename?: 'AuditLogsConnection';
  count: Scalars['Int']['output'];
  edges: Array<AuditLogEdge>;
  nodes: Array<AuditLog>;
  pageInfo: PageInfo;
};

export type BlockUserInput = {
  isBlocked: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type BlockUserPayload = {
  __typename?: 'BlockUserPayload';
  user?: Maybe<User>;
};

export type BookClassInput = {
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  mboClassId: Scalars['Int']['input'];
  mboStudioId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type BookClassPayload = {
  __typename?: 'BookClassPayload';
  mboClass: MboClass;
};

export type BookSessionInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  calendlyId?: InputMaybe<Scalars['ID']['input']>;
  chatRoomId?: InputMaybe<Scalars['ID']['input']>;
  coachId: Scalars['ID']['input'];
  duration: Scalars['Int']['input'];
  eventId?: InputMaybe<Scalars['ID']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  joinURL?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  sessionStyle?: InputMaybe<CoachSessionStyle>;
  sessionSubtype?: InputMaybe<CoachSessionSubtype>;
  sessionType?: InputMaybe<CoachSessionType>;
  start: Scalars['ZonedDateTime']['input'];
  useCronofy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookSessionPayload = {
  __typename?: 'BookSessionPayload';
  session: CoachSession;
};

export enum BotKind {
  Automessages = 'automessages',
  Methodology = 'methodology',
}

export type BulkReassignCoachMembersPayload = {
  __typename?: 'BulkReassignCoachMembersPayload';
  userCountReassigned: Scalars['Int']['output'];
  userIdsNotReassigned: Array<Scalars['String']['output']>;
};

export type Calendar = Node & {
  __typename?: 'Calendar';
  duration: Scalars['Int']['output'];
  end: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  start: Scalars['ZonedDateTime']['output'];
};

export type CalendarBuffer = {
  __typename?: 'CalendarBuffer';
  afterInMinutes: Scalars['Int']['output'];
  beforeInMinutes: Scalars['Int']['output'];
};

export type CalendarProfile = {
  __typename?: 'CalendarProfile';
  platform?: Maybe<ClientCalendarPlatform>;
  profileConnected: Scalars['Boolean']['output'];
  profileId: Scalars['String']['output'];
  profileInitialSyncRequired: Scalars['Boolean']['output'];
  profileName: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  providerService?: Maybe<Scalars['String']['output']>;
};

export type CancelClassInput = {
  mboClassId: Scalars['Int']['input'];
  mboStudioId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CancelClassPayload = {
  __typename?: 'CancelClassPayload';
  mboClass: MboClass;
};

export type Challenge = Node & {
  __typename?: 'Challenge';
  activity: ChallengesActivityConnection;
  auditLogs: AuditLogsConnection;
  background?: Maybe<Photo>;
  badge?: Maybe<Photo>;
  challengeType: ChallengeType;
  clientSpecific: Scalars['Boolean']['output'];
  clients: ChallengeClientsConnection;
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  createdAt: Scalars['ZonedDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  /** @deprecated Use endDateTime */
  endDate: Scalars['LocalDate']['output'];
  endDateTime: Scalars['ZonedDateTime']['output'];
  enrolled: Scalars['Boolean']['output'];
  featured: Scalars['Boolean']['output'];
  goal: Scalars['Float']['output'];
  hasSeenEarnedBadge: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  participants: ChallengeParticipantConnection;
  place: Scalars['String']['output'];
  qualifyingContentGenres: Array<Genre>;
  /** @deprecated Use qualifyingContentGenres */
  qualifyingGenres: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  scoreUnit: ScoreUnit;
  share?: Maybe<Share>;
  /** @deprecated Use startDateTime */
  startDate: Scalars['LocalDate']['output'];
  startDateTime: Scalars['ZonedDateTime']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  team: Scalars['Boolean']['output'];
  teamScore: Scalars['Float']['output'];
  teams: ChallengeTeamConnection;
  title: Scalars['String']['output'];
  /** @deprecated Use scoreUnit */
  unit: Scalars['String']['output'];
};

export type ChallengeActivityArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeClientsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeParticipantsArgs = {
  filter?: InputMaybe<ParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ParticipantsOrderBy>>;
};

export type ChallengeTeamsArgs = {
  filter?: InputMaybe<ChallengeTeamsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeClientsConnection = Connection & {
  __typename?: 'ChallengeClientsConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChallengeClientsEdge>;
  nodes: Array<Client>;
  pageInfo: PageInfo;
};

export type ChallengeClientsEdge = Edge & {
  __typename?: 'ChallengeClientsEdge';
  cursor: Scalars['String']['output'];
  node: Client;
};

export type ChallengeEdge = Edge & {
  __typename?: 'ChallengeEdge';
  cursor: Scalars['String']['output'];
  node: Challenge;
};

export type ChallengeFilter = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum ChallengeOrder {
  Enabled = 'enabled',
  EndDate = 'endDate',
  Featured = 'featured',
  StartDate = 'startDate',
  Title = 'title',
}

export type ChallengeParticipantConnection = Connection & {
  __typename?: 'ChallengeParticipantConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChallengeParticipantsEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ChallengeParticipantsEdge = Edge & {
  __typename?: 'ChallengeParticipantsEdge';
  challengeTeam?: Maybe<ChallengeTeam>;
  completed: Scalars['Boolean']['output'];
  cursor: Scalars['String']['output'];
  node: User;
  place: Scalars['String']['output'];
  score: Scalars['Float']['output'];
};

export type ChallengeSort = {
  field: ChallengeOrder;
  order: OrderDirection;
};

export type ChallengeTeam = Node & {
  __typename?: 'ChallengeTeam';
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  id: Scalars['ID']['output'];
  joined: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  participants: ChallengeParticipantConnection;
};

export type ChallengeTeamParticipantsArgs = {
  filter?: InputMaybe<ParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ParticipantsOrderBy>>;
};

export type ChallengeTeamConnection = Connection & {
  __typename?: 'ChallengeTeamConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChallengeTeamsEdge>;
  nodes: Array<ChallengeTeam>;
  pageInfo: PageInfo;
};

export type ChallengeTeamsEdge = Edge & {
  __typename?: 'ChallengeTeamsEdge';
  completed: Scalars['Boolean']['output'];
  cursor: Scalars['String']['output'];
  node: ChallengeTeam;
  place: Scalars['String']['output'];
  score: Scalars['Float']['output'];
};

export type ChallengeTeamsFilter = {
  appendSelf?: InputMaybe<Scalars['Boolean']['input']>;
  excludeTeamsWithoutScore?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ChallengeType {
  ClassCount = 'ClassCount',
  Distance = 'Distance',
  Time = 'Time',
}

export type ChallengesActivityConnection = Connection & {
  __typename?: 'ChallengesActivityConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChallengesActivityEdge>;
  nodes: Array<Activity>;
  pageInfo: PageInfo;
};

export type ChallengesActivityEdge = Edge & {
  __typename?: 'ChallengesActivityEdge';
  cursor: Scalars['String']['output'];
  node: Activity;
};

export type ChallengesFilter = {
  excludeActiveChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  excludeEarnedBadgesSeen?: InputMaybe<Scalars['Boolean']['input']>;
  excludeFutureChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  excludeIncompleteChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  excludePastChallenges?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChatRoom = Node & {
  __typename?: 'ChatRoom';
  app?: Maybe<App>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  /** @deprecated No longer supported */
  isStarred: Scalars['Boolean']['output'];
  kind: ChatRoomKind;
  lastMessageRead?: Maybe<Scalars['ZonedDateTime']['output']>;
  lastMessageReceivedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  messages: ChatRoomMessagesConnection;
  name?: Maybe<Scalars['String']['output']>;
  users: ChatRoomUsersConnection;
};

export type ChatRoomMessagesArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export enum ChatRoomKind {
  Bot = 'bot',
  Direct = 'direct',
  Generic = 'generic',
  /** @deprecated No longer supported */
  Team = 'team',
}

export type ChatRoomMessagesConnection = Connection & {
  __typename?: 'ChatRoomMessagesConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChatRoomMessagesEdge>;
  nodes: Array<Message>;
  pageInfo: PageInfo;
  unreadMessageCount: Scalars['Int']['output'];
};

export type ChatRoomMessagesEdge = Edge & {
  __typename?: 'ChatRoomMessagesEdge';
  cursor: Scalars['String']['output'];
  node: Message;
};

export type ChatRoomUsersConnection = Connection & {
  __typename?: 'ChatRoomUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChatRoomUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ChatRoomUsersEdge = Edge & {
  __typename?: 'ChatRoomUsersEdge';
  cursor: Scalars['String']['output'];
  lastMessageRead?: Maybe<Scalars['ZonedDateTime']['output']>;
  node: User;
};

export type ChatRoomsFilter = {
  apps?: InputMaybe<Array<App>>;
  createdAtDateRange?: InputMaybe<DateRange>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isStarred?: InputMaybe<Scalars['Boolean']['input']>;
  kinds?: InputMaybe<Array<ChatRoomKind>>;
  name?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export enum ChatRoomsOrder {
  LastFitActivity = 'lastFitActivity',
  LastMessageReceivedAt = 'lastMessageReceivedAt',
  LastPerformActivity = 'lastPerformActivity',
  UserJoinedAt = 'userJoinedAt',
  /** @deprecated use lastFitActivity or lastPerformActivity */
  UserLastActivity = 'userLastActivity',
}

export type ChatRoomsOrderBy = {
  direction: OrderDirection;
  order: ChatRoomsOrder;
};

export type ClickThroughMedia = Content &
  Node & {
    __typename?: 'ClickThroughMedia';
    author?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    icon?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    pages: ContentConnection;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type ClickThroughMediaPagesArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ClickThroughMediaRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type Client = Node & {
  __typename?: 'Client';
  auditLogs: AuditLogsConnection;
  calendarPlatform?: Maybe<ClientCalendarPlatform>;
  coaches: ClientUsersConnection;
  collectsMemberData: Scalars['Boolean']['output'];
  collectsMemberDataForEEP: Scalars['Boolean']['output'];
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  cronofyConferencingUrl?: Maybe<Scalars['String']['output']>;
  cronofyExternalCalendarUrl?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  domains: DomainConnection;
  id: Scalars['ID']['output'];
  inactivityDuration?: Maybe<Scalars['Duration']['output']>;
  isArchived: Scalars['Boolean']['output'];
  logo?: Maybe<Photo>;
  maxFamilyMembers: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  needsUsToFixTheirUserNames: Scalars['Boolean']['output'];
  ssoIdentity?: Maybe<SsoIdentity>;
  /** if true, members from this client will be subject to stricter app check enforcement than other clients */
  strictAppCheck: Scalars['Boolean']['output'];
  studios: Array<Studio>;
  users: ClientUsersConnection;
  usesEligibility: Scalars['Boolean']['output'];
  usesEligibilityServiceType: Scalars['Boolean']['output'];
  usesFitnessCenterForEligibility: Scalars['Boolean']['output'];
  usesGlobalSignOut: Scalars['Boolean']['output'];
  usesHighPerformanceCoaching: Scalars['Boolean']['output'];
  usesPersonalTraining: Scalars['Boolean']['output'];
  usesSSO: Scalars['Boolean']['output'];
  /** validLocations are locations with coach assignments */
  validLocations: CoachLocationsConnection;
  videoPlatform?: Maybe<ClientVideoPlatform>;
};

export type ClientAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ClientCoachesArgs = {
  coachName?: InputMaybe<Scalars['String']['input']>;
  excludeInvalidCoaches?: InputMaybe<Scalars['Boolean']['input']>;
  input?: InputMaybe<ConnectionInput>;
  locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  random?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<CoachType>;
};

export type ClientCronofyConferencingUrlArgs = {
  returnUrl: Scalars['String']['input'];
};

export type ClientCronofyExternalCalendarUrlArgs = {
  returnUrl: Scalars['String']['input'];
};

export type ClientDomainsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ClientStudiosArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  includeCentralStudio?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type ClientValidLocationsArgs = {
  filter?: InputMaybe<LocationsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export enum ClientCalendarPlatform {
  Apple = 'apple',
  Exchange = 'exchange',
  ExosOnly = 'exos_only',
  Google = 'google',
  LiveConnect = 'live_connect',
  Office365 = 'office365',
}

export type ClientUsersConnection = Connection & {
  __typename?: 'ClientUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<ClientUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ClientUsersEdge = Edge & {
  __typename?: 'ClientUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export enum ClientVideoPlatform {
  EightByEight = 'eight_by_eight',
  GoTo = 'go_to',
  Indeed = 'indeed',
  Integrated = 'integrated',
  MsTeams = 'ms_teams',
  Zoom = 'zoom',
}

export type ClientsFilter = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CoachBasedActivity = {
  coachAvatar?: Maybe<Photo>;
  coachFirstName?: Maybe<Scalars['String']['output']>;
  coachLastName?: Maybe<Scalars['String']['output']>;
};

export type CoachData = {
  __typename?: 'CoachData';
  availability: UserAvailabilityConnection;
  availableForMemberAssignment: Scalars['Boolean']['output'];
  bio: Scalars['String']['output'];
  bodyPhoto?: Maybe<Photo>;
  calendarBuffer: CalendarBuffer;
  calendarConnected: Scalars['Boolean']['output'];
  calendarProfiles: Array<CalendarProfile>;
  calendlyUrl?: Maybe<Scalars['String']['output']>;
  clients: RootClientsConnection;
  coachUsers: CoachUsersConnection;
  coachingStyle?: Maybe<CoachingStyle>;
  conferencingProfiles: Array<ConferencingProfile>;
  cronofyAuthUrl?: Maybe<Scalars['String']['output']>;
  cronofyConferencingUrl?: Maybe<Scalars['String']['output']>;
  cronofyElementToken?: Maybe<Scalars['String']['output']>;
  cronofyExternalCalendarUrl?: Maybe<Scalars['String']['output']>;
  cronofySub?: Maybe<Scalars['String']['output']>;
  cronofyTimezone?: Maybe<Scalars['ZoneId']['output']>;
  dailySessionLimit?: Maybe<Scalars['Int']['output']>;
  degrees: Array<Scalars['String']['output']>;
  externalCalendarConnected?: Maybe<ClientCalendarPlatform>;
  fitCoachId?: Maybe<Scalars['String']['output']>;
  headshot?: Maybe<Photo>;
  id: Scalars['ID']['output'];
  inOfficeDays: Array<Scalars['Int']['output']>;
  /** @deprecated Use calendlyUrl instead */
  inPersonCalendlyUrl?: Maybe<Scalars['String']['output']>;
  locations: CoachLocationsConnection;
  mboStaffId?: Maybe<Scalars['String']['output']>;
  passions: Array<Scalars['String']['output']>;
  sessions: UserCoachSessionsConnection;
  supportedCountries: Array<Country>;
  supportedGoals: Array<Goal>;
  titles: Array<Scalars['String']['output']>;
  totalUnreadMessageCount: Scalars['Int']['output'];
  touchPoints: CoachTouchPointConnection;
  type?: Maybe<CoachType>;
  videoPlatformConnected?: Maybe<ClientVideoPlatform>;
  /** @deprecated We do not use Zoom API directly anymore */
  zoomId?: Maybe<Scalars['String']['output']>;
};

export type CoachDataAvailabilityArgs = {
  bypassOneDayDelay?: InputMaybe<Scalars['Boolean']['input']>;
  duration: Scalars['Int']['input'];
  interval: Scalars['Int']['input'];
  sessionStyle?: InputMaybe<CoachSessionStyle>;
  start: Scalars['ZonedDateTime']['input'];
};

export type CoachDataClientsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachDataCoachUsersArgs = {
  filter?: InputMaybe<CoachUsersFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<CoachUsersOrderBy>>;
};

export type CoachDataCronofyAuthUrlArgs = {
  returnUrl: Scalars['String']['input'];
};

export type CoachDataCronofyConferencingUrlArgs = {
  returnUrl: Scalars['String']['input'];
  videoPlatform?: InputMaybe<ClientVideoPlatform>;
};

export type CoachDataCronofyElementTokenArgs = {
  permissions?: InputMaybe<Array<CronofyElementPermission>>;
};

export type CoachDataCronofyExternalCalendarUrlArgs = {
  calendarPlatform?: InputMaybe<ClientCalendarPlatform>;
  returnUrl: Scalars['String']['input'];
};

export type CoachDataLocationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachDataSessionsArgs = {
  filter?: InputMaybe<CoachSessionFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<CoachSessionOrderBy>>;
};

export type CoachDataTouchPointsArgs = {
  filter?: InputMaybe<TouchPointFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<TouchPointsOrderBy>>;
};

export type CoachLocation = Node & {
  __typename?: 'CoachLocation';
  auditLogs: AuditLogsConnection;
  coaches: LocationCoachesConnection;
  id: Scalars['ID']['output'];
  isHeadquarters: Scalars['Boolean']['output'];
  mboLocationId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stateCode?: Maybe<Scalars['String']['output']>;
  studio?: Maybe<Studio>;
};

export type CoachLocationAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachLocationCoachesArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachLocationsConnection = Connection & {
  __typename?: 'CoachLocationsConnection';
  count: Scalars['Int']['output'];
  edges: Array<CoachLocationsEdge>;
  nodes: Array<CoachLocation>;
  pageInfo: PageInfo;
};

export type CoachLocationsEdge = Edge & {
  __typename?: 'CoachLocationsEdge';
  cursor: Scalars['String']['output'];
  node: CoachLocation;
};

export type CoachLocationsFilter = {
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  studioIsArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CoachSession = Node & {
  __typename?: 'CoachSession';
  additionalInfo?: Maybe<Scalars['String']['output']>;
  attendance?: Maybe<CoachSessionAttendance>;
  attended?: Maybe<Scalars['Boolean']['output']>;
  calendar?: Maybe<Calendar>;
  cancelUrl?: Maybe<Scalars['String']['output']>;
  cancellableUntil: Scalars['ZonedDateTime']['output'];
  chatRoom?: Maybe<ChatRoom>;
  coach: User;
  id: Scalars['ID']['output'];
  joinableTime: Scalars['ZonedDateTime']['output'];
  location?: Maybe<CoachLocation>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  member: User;
  reason?: Maybe<Scalars['String']['output']>;
  rescheduleUrl?: Maybe<Scalars['String']['output']>;
  sessionStyle: CoachSessionStyle;
  sessionSubtype?: Maybe<CoachSessionSubtype>;
  sessionType?: Maybe<CoachSessionType>;
  /** @deprecated Use sessionType instead */
  type: Scalars['String']['output'];
};

export enum CoachSessionAttendance {
  Attended = 'Attended',
  Excused = 'Excused',
  NoShow = 'NoShow',
}

export type CoachSessionCredits = {
  __typename?: 'CoachSessionCredits';
  count: Scalars['Int']['output'];
  rowVersion: Scalars['Int']['output'];
};

export type CoachSessionFilter = {
  attendance?: InputMaybe<CoachSessionAttendance>;
  attendances?: InputMaybe<Array<InputMaybe<CoachSessionAttendance>>>;
  coachId?: InputMaybe<Scalars['ID']['input']>;
  coachSessionStyle?: InputMaybe<Array<CoachSessionStyle>>;
  dateRange?: InputMaybe<DateRange>;
  memberName?: InputMaybe<Scalars['String']['input']>;
  sessionType?: InputMaybe<CoachSessionType>;
  sessionTypes?: InputMaybe<Array<CoachSessionType>>;
};

export enum CoachSessionOrder {
  SessionTime = 'sessionTime',
}

export type CoachSessionOrderBy = {
  direction: OrderDirection;
  order: CoachSessionOrder;
};

export enum CoachSessionStyle {
  InPerson = 'InPerson',
  Virtual = 'Virtual',
}

export enum CoachSessionSubtype {
  BodyCompositionAssessment = 'BodyCompositionAssessment',
  ErgonomicEvaluation = 'ErgonomicEvaluation',
  MovementAssessment = 'MovementAssessment',
  NewMemberOrientation = 'NewMemberOrientation',
}

export enum CoachSessionType {
  CoachingSession = 'CoachingSession',
  PersonalTraining = 'PersonalTraining',
  UserCoachingConsult = 'UserCoachingConsult',
  UserNutritionConsult = 'UserNutritionConsult',
}

export type CoachTouchPoint = Node & {
  __typename?: 'CoachTouchPoint';
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  description: Scalars['String']['output'];
  due: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  member: User;
  metadata?: Maybe<CoachTouchPointMetadata>;
  status: CoachTouchPointStatus;
  title: Scalars['String']['output'];
  type: CoachTouchPointType;
};

export type CoachTouchPointConnection = Connection & {
  __typename?: 'CoachTouchPointConnection';
  count: Scalars['Int']['output'];
  edges: Array<CoachTouchPointEdge>;
  nodes: Array<CoachTouchPoint>;
  pageInfo: PageInfo;
};

export type CoachTouchPointEdge = Edge & {
  __typename?: 'CoachTouchPointEdge';
  cursor: Scalars['String']['output'];
  node: CoachTouchPoint;
};

export type CoachTouchPointMetadata = CoachTouchPointSessionMetadata;

export enum CoachTouchPointOrder {
  Due = 'due',
}

export type CoachTouchPointSessionMetadata = {
  __typename?: 'CoachTouchPointSessionMetadata';
  isCancelled: Scalars['Boolean']['output'];
  isRebooked: Scalars['Boolean']['output'];
  session?: Maybe<CoachSession>;
};

export enum CoachTouchPointStatus {
  Completed = 'Completed',
  Incomplete = 'Incomplete',
}

export enum CoachTouchPointType {
  AssessmentNotCompleted = 'AssessmentNotCompleted',
  CompleteFirstDailyCheckIn = 'CompleteFirstDailyCheckIn',
  Custom = 'Custom',
  CustomPlanAdherence = 'CustomPlanAdherence',
  CustomPlanCheckInNote = 'CustomPlanCheckInNote',
  CustomPlanExpired = 'CustomPlanExpired',
  CustomPlanExpiring = 'CustomPlanExpiring',
  DailyCheckInNotCompleted = 'DailyCheckInNotCompleted',
  NewMemberActivationAtRisk = 'NewMemberActivationAtRisk',
  OneWeekPostSessionCheckIn = 'OneWeekPostSessionCheckIn',
  ReassessmentReminder = 'ReassessmentReminder',
  SendMessageReminder = 'SendMessageReminder',
  SessionReminder = 'SessionReminder',
  TrackAttendance = 'TrackAttendance',
}

export enum CoachType {
  Coach = 'Coach',
  Dietitian = 'Dietitian',
  PerformCoach = 'PerformCoach',
}

export enum CoachUserCoachingExperienceTypeFilter {
  HighPerformance = 'highPerformance',
  Wellbeing = 'wellbeing',
}

export enum CoachUserGeneralFilter {
  HasUnreadMessages = 'hasUnreadMessages',
  IsStarred = 'isStarred',
}

export enum CoachUserSessionFilter {
  HasAttendedSession = 'hasAttendedSession',
  HasUpcomingSession = 'hasUpcomingSession',
  NoAttendedSession = 'noAttendedSession',
  NoUpcomingSession = 'noUpcomingSession',
}

export enum CoachUserStatusFilter {
  IsActive = 'isActive',
  IsInactive = 'isInactive',
  IsNew = 'isNew',
}

export type CoachUsersConnection = Connection & {
  __typename?: 'CoachUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<CoachUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type CoachUsersEdge = Edge & {
  __typename?: 'CoachUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type CoachUsersFilter = {
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  coachingExperienceType?: InputMaybe<
    Array<CoachUserCoachingExperienceTypeFilter>
  >;
  createdAtDateRange?: InputMaybe<DateRange>;
  general?: InputMaybe<Array<CoachUserGeneralFilter>>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']['input']>;
  isStarred?: InputMaybe<Scalars['Boolean']['input']>;
  recentActivityDateRange?: InputMaybe<DateRange>;
  session?: InputMaybe<Array<CoachUserSessionFilter>>;
  status?: InputMaybe<Array<CoachUserStatusFilter>>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export enum CoachUsersOrder {
  /** @deprecated No longer supported */
  LastFitActivity = 'lastFitActivity',
  LastMessageReceivedAt = 'lastMessageReceivedAt',
  LatestUserActivityStartTime = 'latestUserActivityStartTime',
}

export type CoachUsersOrderBy = {
  direction: OrderDirection;
  order: CoachUsersOrder;
};

export enum CoachingStyle {
  Hybrid = 'Hybrid',
  Virtual = 'Virtual',
}

export type ConferencingProfile = {
  __typename?: 'ConferencingProfile';
  platform?: Maybe<ClientVideoPlatform>;
  profileConnected: Scalars['Boolean']['output'];
  profileId: Scalars['String']['output'];
  profileName: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
};

export type Connection = {
  count: Scalars['Int']['output'];
  edges: Array<Edge>;
  nodes: Array<Node>;
  pageInfo: PageInfo;
};

export type ConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Content = {
  author?: Maybe<Scalars['String']['output']>;
  contentEquipment: Array<Equipment>;
  contentGenre?: Maybe<Genre>;
  contentLevel?: Maybe<Level>;
  contentSplit?: Maybe<Split>;
  contentTime?: Maybe<ContentTime>;
  feedback?: Maybe<FeedbackKind>;
  heroPhoto?: Maybe<Photo>;
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  /** @deprecated use contentLevel */
  level?: Maybe<Scalars['String']['output']>;
  recommendations: ContentRecommendationsConnection;
  saved?: Maybe<Scalars['Boolean']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  /** @deprecated use contentTime */
  time?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  trainingCommitment?: Maybe<Scalars['String']['output']>;
};

export type ContentRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentAttribute = {
  __typename?: 'ContentAttribute';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ContentCollection = {
  __typename?: 'ContentCollection';
  id: Scalars['ID']['output'];
  sections: ContentCollectionSectionsConnection;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ContentCollectionSectionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentCollectionSectionsConnection = Connection & {
  __typename?: 'ContentCollectionSectionsConnection';
  count: Scalars['Int']['output'];
  edges: Array<ContentCollectionSectionsEdge>;
  nodes: Array<ContentSection>;
  pageInfo: PageInfo;
};

export type ContentCollectionSectionsEdge = Edge & {
  __typename?: 'ContentCollectionSectionsEdge';
  cursor: Scalars['String']['output'];
  node: ContentSection;
};

export type ContentConnection = SimpleContentConnection & {
  __typename?: 'ContentConnection';
  completedCount: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  edges: Array<ContentEdge>;
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type ContentEdge = SimpleContentEdge & {
  __typename?: 'ContentEdge';
  completed: Scalars['Boolean']['output'];
  cursor: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  node: Content;
  progress?: Maybe<Scalars['Float']['output']>;
};

export type ContentFeedbackPayload = {
  __typename?: 'ContentFeedbackPayload';
  content: Content;
};

export type ContentFilter = {
  associatedProject?: InputMaybe<Array<Scalars['String']['input']>>;
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  component?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Array<Scalars['Int']['input']>>;
  equipment?: InputMaybe<Array<Scalars['String']['input']>>;
  gameplanPrimaryComponent?: InputMaybe<Array<Scalars['String']['input']>>;
  gameplanPrimarySubcomponents?: InputMaybe<Array<Scalars['String']['input']>>;
  gameplanSecondaryComponent?: InputMaybe<Array<Scalars['String']['input']>>;
  gameplanSecondarySubcomponents?: InputMaybe<
    Array<Scalars['String']['input']>
  >;
  genre?: InputMaybe<Array<Scalars['String']['input']>>;
  intensity?: InputMaybe<Array<Scalars['String']['input']>>;
  isExperiential?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Array<Scalars['String']['input']>>;
  performanceQuality?: InputMaybe<Array<Scalars['String']['input']>>;
  split?: InputMaybe<Array<Scalars['String']['input']>>;
  subGenre?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ContentKind {
  Article = 'article',
  Inappsurvey = 'inappsurvey',
  Link = 'link',
  Video = 'video',
}

export enum ContentOrder {
  PublishedAt = 'published_at',
  Title = 'title',
}

export type ContentOrderBy = {
  direction: OrderDirection;
  order?: InputMaybe<ContentOrder>;
};

export type ContentRecommendationsConnection = SimpleContentConnection & {
  __typename?: 'ContentRecommendationsConnection';
  count: Scalars['Int']['output'];
  edges: Array<ContentRecommendationsEdge>;
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type ContentRecommendationsEdge = SimpleContentEdge & {
  __typename?: 'ContentRecommendationsEdge';
  cursor: Scalars['String']['output'];
  node: Content;
};

export type ContentSavedConnection = SimpleContentConnection & {
  __typename?: 'ContentSavedConnection';
  count: Scalars['Int']['output'];
  edges: Array<ContentSavedEdge>;
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type ContentSavedEdge = SimpleContentEdge & {
  __typename?: 'ContentSavedEdge';
  cursor: Scalars['String']['output'];
  node: Content;
};

export type ContentSection = Content &
  Node & {
    __typename?: 'ContentSection';
    author?: Maybe<Scalars['String']['output']>;
    bannerPhoto?: Maybe<Photo>;
    brand?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    contents: ContentConnection;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    logoPhoto?: Maybe<Photo>;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type ContentSectionContentsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentSectionRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentTime = {
  __typename?: 'ContentTime';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Country = {
  __typename?: 'Country';
  alpha2: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CreateChallengeInput = {
  backgroundId?: InputMaybe<Scalars['String']['input']>;
  badgeId?: InputMaybe<Scalars['String']['input']>;
  challengeType: ChallengeType;
  clientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  clientSpecific?: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  distanceUnit?: InputMaybe<DistanceUnit>;
  enabled: Scalars['Boolean']['input'];
  endDate: Scalars['LocalDate']['input'];
  featured: Scalars['Boolean']['input'];
  goal: Scalars['Float']['input'];
  qualifyingGenres: Array<Scalars['String']['input']>;
  startDate: Scalars['LocalDate']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['Boolean']['input']>;
  teams?: InputMaybe<Array<CreateChallengeTeamInput>>;
  title: Scalars['String']['input'];
};

export type CreateChallengePayload = {
  __typename?: 'CreateChallengePayload';
  challenge: Challenge;
};

export type CreateChallengeTeamInput = {
  name: Scalars['String']['input'];
};

export type CreateChatRoomInput = {
  app?: InputMaybe<App>;
  initialMessage?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<ChatRoomKind>;
  name: Scalars['String']['input'];
  users: Array<Scalars['String']['input']>;
};

export type CreateClientInput = {
  calendarPlatform?: InputMaybe<ClientCalendarPlatform>;
  collectsMemberData?: InputMaybe<Scalars['Boolean']['input']>;
  collectsMemberDataForEEP?: InputMaybe<Scalars['Boolean']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  domainIds?: InputMaybe<Array<Scalars['String']['input']>>;
  inactivityDuration?: InputMaybe<Scalars['Duration']['input']>;
  logoId?: InputMaybe<Scalars['String']['input']>;
  maxFamilyMembers?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  needsUsToFixTheirUserNames?: InputMaybe<Scalars['Boolean']['input']>;
  ssoConnection?: InputMaybe<Scalars['String']['input']>;
  strictAppCheck?: InputMaybe<Scalars['Boolean']['input']>;
  usesEligibilityServiceType?: InputMaybe<Scalars['Boolean']['input']>;
  usesFitnessCenterForEligibility?: InputMaybe<Scalars['Boolean']['input']>;
  usesGlobalSignOut?: InputMaybe<Scalars['Boolean']['input']>;
  videoPlatform?: InputMaybe<ClientVideoPlatform>;
};

export type CreateCoachLocationInput = {
  isHeadquarters: Scalars['Boolean']['input'];
  mboLocationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  stateCode: Scalars['String']['input'];
  studioId: Scalars['String']['input'];
};

export type CreateCoachTouchPointInput = {
  coachId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  due?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  memberId: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  sessionTime?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: CoachTouchPointType;
};

export type CreateDomainInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
};

export type CreateEligibleEmailAddressInput = {
  clientId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hpcEligibilityEnd?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  hpcEligibilityStart?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateNotificationOptionInput = {
  app: App;
  description: Scalars['String']['input'];
  featureFlag?: InputMaybe<Scalars['String']['input']>;
  iterableId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  visible: Scalars['Boolean']['input'];
};

export type CreatePerformanceQualityScoreInput = {
  component: Scalars['String']['input'];
  performanceQuality: Scalars['String']['input'];
  score: Scalars['Int']['input'];
  subComponent: Scalars['String']['input'];
};

export type CreatePlanInput = {
  /** If true, will allow admin users in the dev environment to override start date restrictions */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  planItems?: InputMaybe<Array<CreatePlanItemInput>>;
  startDate: Scalars['LocalDate']['input'];
  targetedPerformanceQualities?: InputMaybe<Array<PerformanceQuality>>;
  userId: Scalars['ID']['input'];
};

export type CreatePlanItemInput = {
  component?: InputMaybe<Scalars['String']['input']>;
  contentId?: InputMaybe<Scalars['ID']['input']>;
  date: Scalars['LocalDate']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type: PlanItemType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePlanPayload = {
  __typename?: 'CreatePlanPayload';
  plan: Plan;
};

export type CreateStudioInput = {
  clientId: Scalars['String']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isCentral?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual: Scalars['Boolean']['input'];
  mboServiceId: Scalars['String']['input'];
  mboStudioId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};

export type CreateUserGoalInput = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
  coachNote?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  memberId?: InputMaybe<Scalars['ID']['input']>;
  type: UserGoalType;
};

export type CreateUserGoalPayload = {
  __typename?: 'CreateUserGoalPayload';
  user: User;
};

export type CreateUserInput = {
  adminCreate?: InputMaybe<Scalars['Boolean']['input']>;
  app?: InputMaybe<App>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  isCoach?: InputMaybe<Scalars['Boolean']['input']>;
  isTestUser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  shareDataWithEmployer?: InputMaybe<Scalars['Boolean']['input']>;
  shareEEPData?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  user: User;
};

export type CreateUsersInput = {
  users: Array<CreateUserInput>;
};

export type CreateUsersPayload = {
  __typename?: 'CreateUsersPayload';
  users: Array<User>;
};

export enum CronofyElementPermission {
  Agenda = 'agenda',
  Availability = 'availability',
  ManagedAvailability = 'managed_availability',
}

export type CustomPlanActiveDaysFilter = {
  localDateRange: RequiredLocalDateRange;
};

export type CustomPlansFilter = {
  localDateRange?: InputMaybe<LocalDateRange>;
};

export type CustomTaskPlanItem = Node &
  PlanItem & {
    __typename?: 'CustomTaskPlanItem';
    completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated Use contentComponent instead */
    component?: Maybe<Scalars['String']['output']>;
    contentComponent?: Maybe<ContentAttribute>;
    date: Scalars['LocalDate']['output'];
    description: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    order: Scalars['Int']['output'];
    title: Scalars['String']['output'];
    type: PlanItemType;
    url?: Maybe<Scalars['String']['output']>;
  };

export enum DateFilter {
  Future = 'Future',
  Past = 'Past',
}

export type DateRange = {
  end?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  start?: InputMaybe<Scalars['ZonedDateTime']['input']>;
};

export type DeleteFitCoachPayload = {
  __typename?: 'DeleteFitCoachPayload';
  user: User;
};

export type DeletePlanPayload = {
  __typename?: 'DeletePlanPayload';
  plan: Plan;
};

export type DeleteUserGoalPayload = {
  __typename?: 'DeleteUserGoalPayload';
  user: User;
};

export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  userId: Scalars['ID']['output'];
};

export type Device = {
  __typename?: 'Device';
  app?: Maybe<App>;
  createdAt: Scalars['ZonedDateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updatedAt: Scalars['ZonedDateTime']['output'];
  user: User;
};

export type Distance = {
  __typename?: 'Distance';
  unit?: Maybe<DistanceUnit>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type DistanceInput = {
  unit: DistanceUnit;
  value: Scalars['Float']['input'];
};

export enum DistanceUnit {
  Kilometers = 'Kilometers',
  Meters = 'Meters',
  Miles = 'Miles',
  Yards = 'Yards',
}

export type Domain = Node & {
  __typename?: 'Domain';
  client?: Maybe<Client>;
  createdAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type DomainConnection = {
  __typename?: 'DomainConnection';
  count: Scalars['Int']['output'];
  edges: Array<DomainEdge>;
  nodes: Array<Domain>;
  pageInfo: PageInfo;
};

export type DomainEdge = Edge & {
  __typename?: 'DomainEdge';
  cursor: Scalars['String']['output'];
  node: Domain;
};

export type DomainsFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Edge = {
  cursor: Scalars['String']['output'];
  node: Node;
};

export type EditActivityInput = {
  distance?: InputMaybe<DistanceInput>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['ZonedDateTime']['input']>;
};

export type EligibleEmailAddress = Node & {
  __typename?: 'EligibleEmailAddress';
  archivedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  auditLogs: AuditLogsConnection;
  client?: Maybe<Client>;
  createdAt: Scalars['ZonedDateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['String']['output']>;
  /** @deprecated firstName is no longer required */
  firstName?: Maybe<Scalars['String']['output']>;
  hasHpcEligibility: Scalars['Boolean']['output'];
  hpcEligibilityEnd?: Maybe<Scalars['ZonedDateTime']['output']>;
  hpcEligibilityStart?: Maybe<Scalars['ZonedDateTime']['output']>;
  id: Scalars['ID']['output'];
  invitingRelationship?: Maybe<FamilyRelationship>;
  invitingUser?: Maybe<User>;
  isArchived: Scalars['Boolean']['output'];
  isStaff: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  shareInvitation?: Maybe<Share>;
  updatedAt: Scalars['ZonedDateTime']['output'];
  user?: Maybe<User>;
};

export type EligibleEmailAddressAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type EligibleEmailAddressDetailsInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type EligibleEmailAddressesFilter = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['String']['input']>;
  hasHpcEligibility?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type EmergencyContactDetails = {
  __typename?: 'EmergencyContactDetails';
  address?: Maybe<Address>;
  emergencyContact?: Maybe<EmergencyContact>;
  updatedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type EnumAnswer = {
  __typename?: 'EnumAnswer';
  choices: Array<Scalars['String']['output']>;
  enumValue: Array<Scalars['Int']['output']>;
};

export type Equipment = {
  __typename?: 'Equipment';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ExosContentPlanItem = Node &
  PlanItem & {
    __typename?: 'ExosContentPlanItem';
    completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated Use contentComponent instead */
    component?: Maybe<Scalars['String']['output']>;
    content: Content;
    contentComponent?: Maybe<ContentAttribute>;
    date: Scalars['LocalDate']['output'];
    id: Scalars['ID']['output'];
    order: Scalars['Int']['output'];
    type: PlanItemType;
  };

export enum FamilyRelationship {
  Dependent = 'dependent',
  Spouse = 'spouse',
}

export enum FeedbackContextKind {
  Application = 'application',
  Challenge = 'challenge',
  Practice = 'practice',
  PracticeProgram = 'practiceProgram',
  Program = 'program',
}

export enum FeedbackKind {
  Negative = 'negative',
  Positive = 'positive',
  Undecided = 'undecided',
}

export type FeedbackPayload = {
  __typename?: 'FeedbackPayload';
  challenge?: Maybe<Challenge>;
  content?: Maybe<Content>;
};

export type GameplanMetadata = {
  __typename?: 'GameplanMetadata';
  associatedProject?: Maybe<ContentAttribute>;
  isExperiential?: Maybe<Scalars['Boolean']['output']>;
  primaryComponent?: Maybe<ContentAttribute>;
  primarySubcomponents: Array<Maybe<ContentAttribute>>;
  secondaryComponent?: Maybe<ContentAttribute>;
  secondarySubcomponents: Array<Maybe<ContentAttribute>>;
};

export type Genre = {
  __typename?: 'Genre';
  children: Array<Genre>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Genre>;
  value: Scalars['String']['output'];
};

export type Goal = {
  __typename?: 'Goal';
  description: Scalars['String']['output'];
  type: UserGoalType;
};

export type HeartRate = {
  __typename?: 'HeartRate';
  average: Scalars['Int']['output'];
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export type InAppSurvey = Content &
  Node & {
    __typename?: 'InAppSurvey';
    author?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    feedback?: Maybe<FeedbackKind>;
    finishedBody?: Maybe<Scalars['String']['output']>;
    finishedCardSubtitle?: Maybe<Scalars['String']['output']>;
    finishedCardTitle?: Maybe<Scalars['String']['output']>;
    finishedTitle?: Maybe<Scalars['String']['output']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    initialCardSubtitle?: Maybe<Scalars['String']['output']>;
    initialCardTitle?: Maybe<Scalars['String']['output']>;
    introBody?: Maybe<Scalars['String']['output']>;
    introTitle?: Maybe<Scalars['String']['output']>;
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    link: Scalars['String']['output'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    /** @deprecated User Question.savedAnswer now */
    survey?: Maybe<Survey>;
    surveyTemplate?: Maybe<SurveyTemplate>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type InAppSurveyRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type InviteFamilyMemberInput = {
  email: Scalars['String']['input'];
  relationship: FamilyRelationship;
};

export type InviteFamilyMemberPayload = {
  __typename?: 'InviteFamilyMemberPayload';
  familyInvitation: EligibleEmailAddress;
  user: User;
};

export type JoinChallengePayload = {
  __typename?: 'JoinChallengePayload';
  challenge?: Maybe<Challenge>;
};

export type LastActivity = {
  __typename?: 'LastActivity';
  heart?: Maybe<Scalars['ZonedDateTime']['output']>;
  heartWeb?: Maybe<Scalars['ZonedDateTime']['output']>;
  /** @deprecated prince is deprecated */
  prince?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type Level = {
  __typename?: 'Level';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Link = Content &
  Node & {
    __typename?: 'Link';
    author?: Maybe<Scalars['String']['output']>;
    bannerPhoto?: Maybe<Photo>;
    buttonText?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    link: Scalars['String']['output'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type LinkRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type LocalDateRange = {
  end?: InputMaybe<Scalars['LocalDate']['input']>;
  start?: InputMaybe<Scalars['LocalDate']['input']>;
};

export type LocationCoachesConnection = Connection & {
  __typename?: 'LocationCoachesConnection';
  count: Scalars['Int']['output'];
  edges: Array<LocationCoachesEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type LocationCoachesEdge = Edge & {
  __typename?: 'LocationCoachesEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type LocationsFilter = {
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MachineClient = {
  __typename?: 'MachineClient';
  id: Scalars['ID']['output'];
};

export type ManualActivityInput = {
  distance?: InputMaybe<DistanceInput>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  sourceId: Scalars['String']['input'];
  startTime: Scalars['ZonedDateTime']['input'];
};

export type MboBookingsFilter = {
  dates: DateRange;
};

export type MboClass = Node & {
  __typename?: 'MboClass';
  attendees: MboClassUsersConnection;
  attending: Scalars['Boolean']['output'];
  /** The times at which booking for this class open and close. */
  bookingWindow?: Maybe<ZonedDateTimeRange>;
  cancelled: Scalars['Boolean']['output'];
  category: Scalars['String']['output'];
  client: Client;
  description: Scalars['String']['output'];
  endTime: Scalars['ZonedDateTime']['output'];
  full: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  joinUrl?: Maybe<Scalars['String']['output']>;
  joinableTime: Scalars['ZonedDateTime']['output'];
  location?: Maybe<CoachLocation>;
  mboClassId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  room?: Maybe<Scalars['String']['output']>;
  /** The service category’s schedule type. */
  scheduleType: Scalars['String']['output'];
  staff?: Maybe<User>;
  staffFirstName?: Maybe<Scalars['String']['output']>;
  staffLastName?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['ZonedDateTime']['output'];
  studio: Studio;
  /** The detailed type of the class: in-person, live, or encore */
  subtype: MboClassSubtype;
  /** @deprecated Prefer subtype */
  type: MboClassType;
};

export type MboClassAttendeesArgs = {
  filter?: InputMaybe<MboClassUsersFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type MboClassActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'MboClassActivity';
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']['output']>;
    coachLastName?: Maybe<Scalars['String']['output']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    location?: Maybe<Scalars['String']['output']>;
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    room?: Maybe<Scalars['String']['output']>;
    source?: Maybe<MboClass>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type MboClassConnection = Connection & {
  __typename?: 'MboClassConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootMboClassEdge>;
  nodes: Array<MboClass>;
  pageInfo: PageInfo;
};

export type MboClassDetailsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  mboClassId?: InputMaybe<Scalars['Int']['input']>;
  mboStudioId?: InputMaybe<Scalars['String']['input']>;
};

export type MboClassFilter = {
  classType: MboClassType;
  dates: DateRange;
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum MboClassSubtype {
  Encore = 'Encore',
  InPerson = 'InPerson',
  LiveVirtual = 'LiveVirtual',
}

export enum MboClassType {
  InPerson = 'InPerson',
  Virtual = 'Virtual',
}

export type MboClassUsersConnection = Connection & {
  __typename?: 'MboClassUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<MboClassUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type MboClassUsersEdge = Edge & {
  __typename?: 'MboClassUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type MboClassUsersFilter = {
  excludeAbsentUsers?: InputMaybe<Scalars['Boolean']['input']>;
  includeCancelledUsers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Message = Node & {
  __typename?: 'Message';
  automated: Scalars['Boolean']['output'];
  createdAt: Scalars['ZonedDateTime']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  photos: Array<Photo>;
  previews: Array<MessagePreview>;
  reactions: Array<Reaction>;
  text: Scalars['String']['output'];
  user: User;
};

export type MessagePreview = {
  __typename?: 'MessagePreview';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Photo>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ModalStatus = {
  __typename?: 'ModalStatus';
  id: Scalars['ID']['output'];
  modalSeen: Scalars['Boolean']['output'];
};

export type MultipleChoiceOption = {
  __typename?: 'MultipleChoiceOption';
  description: Scalars['String']['output'];
  optionIdentifier: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  textEntry: Scalars['Boolean']['output'];
};

export type MutateChatRoomPayload = {
  __typename?: 'MutateChatRoomPayload';
  chatRoom?: Maybe<ChatRoom>;
};

export type MutateClientPayload = {
  __typename?: 'MutateClientPayload';
  client: Client;
};

export type MutateCoachLocationPayload = {
  __typename?: 'MutateCoachLocationPayload';
  coachLocation: CoachLocation;
};

export type MutateCoachPayload = {
  __typename?: 'MutateCoachPayload';
  coachData: CoachData;
};

export type MutateDomainPayload = {
  __typename?: 'MutateDomainPayload';
  domain: Domain;
};

export type MutateEligibleEmailAddressInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  hpcEligibilityEnd?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  hpcEligibilityStart?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  id: Scalars['ID']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutateEligibleEmailAddressPayload = {
  __typename?: 'MutateEligibleEmailAddressPayload';
  eligibleEmailAddress: EligibleEmailAddress;
};

export type MutateNotePayload = {
  __typename?: 'MutateNotePayload';
  note?: Maybe<Note>;
};

export type MutateNotificationOptionPayload = {
  __typename?: 'MutateNotificationOptionPayload';
  notificationOption: NotificationOption;
};

export type MutateStudioPayload = {
  __typename?: 'MutateStudioPayload';
  studio: Studio;
};

export type Mutation = {
  __typename?: 'Mutation';
  addManualActivity: ActivityPayload;
  addPerformanceQualityScores: AddPerformanceQualityScoresPayload;
  addReaction: Message;
  addSaved: SavedPayload;
  addToChatRoom: MutateChatRoomPayload;
  addToClient: MutateClientPayload;
  addUserToStudio: AddUserToStudioPayload;
  adminBackfillAssessmentAttributionType: Scalars['Int']['output'];
  adminCancelClass: Scalars['Boolean']['output'];
  adminChangeUserAuthProvider: UpdateUserPayload;
  adminConfirmUser: UpdateUserPayload;
  adminCreateClass: Scalars['Boolean']['output'];
  adminUpdateLastActivity?: Maybe<UpdateUserPayload>;
  adminUpdateUser: UpdateUserPayload;
  answerSurvey: Survey;
  assignAssessments: AssignAssessmentsPayload;
  assignFitCoach: AssignFitCoachPayload;
  assignHistoricalFitCoach: AssignFitCoachPayload;
  assignPractice: AssignPracticePayload;
  assignProgram: AssignProgramPayload;
  backFillIterableMarketing: RootUsersConnection;
  blockUser: BlockUserPayload;
  bookClass: BookClassPayload;
  bookSession: BookSessionPayload;
  bulkReassignCoachMembers: BulkReassignCoachMembersPayload;
  cancelClass: CancelClassPayload;
  cancelSession: Scalars['Boolean']['output'];
  changeEmail: UpdateUserPayload;
  clearCache: Scalars['Boolean']['output'];
  completePractice: UpdatePracticePayload;
  completeProgram: UpdateProgramPayload;
  createChallenge: CreateChallengePayload;
  createChatRoom: MutateChatRoomPayload;
  createClient: MutateClientPayload;
  createCoachLocation: MutateCoachLocationPayload;
  createCoachTouchPoint: CoachTouchPoint;
  /** @deprecated use createFeedback */
  createContentFeedback: ContentFeedbackPayload;
  createDomain: MutateDomainPayload;
  createEligibleEmailAddress: MutateEligibleEmailAddressPayload;
  createFeedback: FeedbackPayload;
  createNotificationOption: MutateNotificationOptionPayload;
  createPlan: CreatePlanPayload;
  createStudio: MutateStudioPayload;
  createUser: CreateUserPayload;
  createUserGoal: CreateUserGoalPayload;
  createUsers: CreateUsersPayload;
  deleteActivity: Scalars['Boolean']['output'];
  deleteAssignedAssessment?: Maybe<AssessmentAssignment>;
  deleteClientEligibleEmails: Scalars['Int']['output'];
  deleteCoachTouchPoint: Scalars['Boolean']['output'];
  deleteFitCoach: DeleteFitCoachPayload;
  deletePerformanceQualityScore: Scalars['Boolean']['output'];
  deletePlan: DeletePlanPayload;
  deleteUser: DeleteUserPayload;
  deleteUserGoal: DeleteUserGoalPayload;
  editActivity: ActivityPayload;
  generateSignedUrl: SignedUrlPayload;
  inviteFamilyMember: InviteFamilyMemberPayload;
  joinChallenge: JoinChallengePayload;
  joinLiveClass: ActivityPayload;
  leaveChallenge: JoinChallengePayload;
  leavePractice: UpdatePracticePayload;
  leaveProgram: UpdateProgramPayload;
  markBadgesSeenForChallenge: Challenge;
  markChatRead: ChatRoom;
  /** @deprecated No longer supported */
  markChatStarred: ChatRoom;
  markPlanItemComplete: PlanItem;
  markSurveyResponseViewed: SurveyResponsePayload;
  markUserStarred: User;
  purgeContents: Scalars['Boolean']['output'];
  purgeWearableData: PurgeWearableDataPayload;
  rateApp: RateAppPayload;
  rebookSession: BookSessionPayload;
  refreshContent: RefreshContentPayload;
  register: RegisterUserPayload;
  registerDevice: Scalars['Boolean']['output'];
  remindFamilyMember: InviteFamilyMemberPayload;
  removeAllUserSurveys: Scalars['Int']['output'];
  removeClient: Scalars['Boolean']['output'];
  removeCoachMembers: Scalars['Boolean']['output'];
  removeDomain: Scalars['Boolean']['output'];
  /** Removes an eligibility row from the database. For special cases only, not to be exposed as a regular operation in Admin without further thought. */
  removeEligibleEmailAddress: Scalars['Boolean']['output'];
  removeFamilyMember: InviteFamilyMemberPayload;
  removeFromChallenge: JoinChallengePayload;
  removeFromChatRoom: MutateChatRoomPayload;
  removeFromClient: MutateClientPayload;
  removeLocation: Scalars['Boolean']['output'];
  removeReaction: Message;
  removeSaved: SavedPayload;
  removeStudio: Scalars['Boolean']['output'];
  removeUserSurveys: Scalars['Int']['output'];
  repairClientUsers: RepairClientUsersPayload;
  reportUser: Scalars['Boolean']['output'];
  resetPreview?: Maybe<Photo>;
  resetProgress: ResetProgressPayload;
  resyncIterableUser?: Maybe<User>;
  /** @deprecated Use syncWearableSamples */
  saveWearableSamples: Scalars['Boolean']['output'];
  sendHistoricalMessage: Message;
  sendMessage: Message;
  sendSupportRequest: Scalars['Boolean']['output'];
  setMinBuildNumber: Scalars['Int']['output'];
  setModalStatus: ModalStatus;
  setNotificationOptions: SetNotificationOptionsPayload;
  setSessionCredits: SessionCreditsPayload;
  setUnitsPreference: UnitsPayload;
  startPractice: StartPracticePayload;
  startProgram: StartProgramPayload;
  /** @deprecated No longer supported */
  syncChatUsers: Scalars['Boolean']['output'];
  syncEligibleEmailAddress: MutateEligibleEmailAddressPayload;
  syncMboMemberships: SyncMboMembershipsPayload;
  syncWearableSamples: Scalars['Boolean']['output'];
  temporaryBackfillSleepOverview: Scalars['Int']['output'];
  triggerIterablePush?: Maybe<TriggerIterablePushPayload>;
  unforgetIterableUser: Scalars['Boolean']['output'];
  unforgetUser: UnforgetUserPayload;
  updateAttendance: CoachSession;
  /** @deprecated Moved to enum column "updateAttendance" */
  updateAttended: CoachSession;
  updateAvatar: UpdateAvatarPayload;
  updateChallenge: CreateChallengePayload;
  updateChatRoom: MutateChatRoomPayload;
  updateClient: MutateClientPayload;
  updateCoachCronofyTimezone: UpdateCoachCronofyTimezoneResponse;
  updateCoachData: UpdateCoachDataPayload;
  updateCoachLocation: MutateCoachLocationPayload;
  updateCoachTouchPoint: CoachTouchPoint;
  updateDomain: MutateDomainPayload;
  updateEligibleEmailAddress: MutateEligibleEmailAddressPayload;
  updateLastActivity?: Maybe<UpdateUserPayload>;
  updateNote: MutateNotePayload;
  updateNotificationOption: MutateNotificationOptionPayload;
  updatePerformanceQualityScore: PerformanceQualityScore;
  updatePlan: UpdatePlanPayload;
  updatePractice: UpdatePracticePayload;
  updateProfileJson: UpdateProfileJsonPayload;
  updateProgram: UpdateProgramPayload;
  updateProgress: ContentEdge;
  updateStudio: MutateStudioPayload;
  updateUnreadCount: Scalars['Int']['output'];
  updateUser: UpdateUserPayload;
  updateUserGoal: UpdateUserGoalPayload;
  /** @deprecated Removed welcome section */
  updateWelcomeSectionDisplay: UpdateUserPayload;
};

export type MutationAddManualActivityArgs = {
  activity: ManualActivityInput;
};

export type MutationAddPerformanceQualityScoresArgs = {
  performanceQualityScores: Array<CreatePerformanceQualityScoreInput>;
};

export type MutationAddReactionArgs = {
  input: AddReactionInput;
};

export type MutationAddSavedArgs = {
  contentId: Scalars['ID']['input'];
};

export type MutationAddToChatRoomArgs = {
  chatRoomId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAddToClientArgs = {
  clientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationAddUserToStudioArgs = {
  studioId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationAdminBackfillAssessmentAttributionTypeArgs = {
  input: AdminBackfillAssessmentAttributionTypeInput;
};

export type MutationAdminCancelClassArgs = {
  input: CancelClassInput;
};

export type MutationAdminChangeUserAuthProviderArgs = {
  input: AdminChangeAuthProviderInput;
};

export type MutationAdminConfirmUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationAdminCreateClassArgs = {
  input: BookClassInput;
};

export type MutationAdminUpdateLastActivityArgs = {
  app: App;
  date?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  platform?: TokenPlatform;
  userId: Scalars['ID']['input'];
};

export type MutationAdminUpdateUserArgs = {
  input: AdminUpdateUserInput;
};

export type MutationAnswerSurveyArgs = {
  input: AnswerSurveyInput;
};

export type MutationAssignAssessmentsArgs = {
  input: AssignAssessmentsInput;
};

export type MutationAssignFitCoachArgs = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAssignHistoricalFitCoachArgs = {
  coachId: Scalars['ID']['input'];
  createdAt: Scalars['ZonedDateTime']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationAssignPracticeArgs = {
  practiceId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationAssignProgramArgs = {
  programId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationBackFillIterableMarketingArgs = {
  input?: InputMaybe<ConnectionInput>;
  startDate?: InputMaybe<Scalars['LocalDate']['input']>;
};

export type MutationBlockUserArgs = {
  input: BlockUserInput;
};

export type MutationBookClassArgs = {
  classId: Scalars['ID']['input'];
};

export type MutationBookSessionArgs = {
  input: BookSessionInput;
};

export type MutationBulkReassignCoachMembersArgs = {
  newCoachId: Scalars['ID']['input'];
  oldCoachId: Scalars['ID']['input'];
  reassignAllUsers?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationCancelClassArgs = {
  classId: Scalars['ID']['input'];
};

export type MutationCancelSessionArgs = {
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  sessionId: Scalars['ID']['input'];
  useCronofy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationChangeEmailArgs = {
  allowSSO?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationCompletePracticeArgs = {
  practiceId: Scalars['ID']['input'];
};

export type MutationCompleteProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationCreateChallengeArgs = {
  input: CreateChallengeInput;
};

export type MutationCreateChatRoomArgs = {
  input: CreateChatRoomInput;
};

export type MutationCreateClientArgs = {
  input: CreateClientInput;
};

export type MutationCreateCoachLocationArgs = {
  input: CreateCoachLocationInput;
};

export type MutationCreateCoachTouchPointArgs = {
  input: CreateCoachTouchPointInput;
};

export type MutationCreateContentFeedbackArgs = {
  contentId: Scalars['ID']['input'];
  context: FeedbackContextKind;
  feedback: FeedbackKind;
};

export type MutationCreateDomainArgs = {
  input: CreateDomainInput;
};

export type MutationCreateEligibleEmailAddressArgs = {
  input: CreateEligibleEmailAddressInput;
};

export type MutationCreateFeedbackArgs = {
  context: FeedbackContextKind;
  feedback: FeedbackKind;
  id: Scalars['ID']['input'];
};

export type MutationCreateNotificationOptionArgs = {
  input: CreateNotificationOptionInput;
};

export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};

export type MutationCreateStudioArgs = {
  input: CreateStudioInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateUserGoalArgs = {
  input: CreateUserGoalInput;
};

export type MutationCreateUsersArgs = {
  input: CreateUsersInput;
};

export type MutationDeleteActivityArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteAssignedAssessmentArgs = {
  assignmentId: Scalars['ID']['input'];
};

export type MutationDeleteClientEligibleEmailsArgs = {
  clientId: Scalars['String']['input'];
};

export type MutationDeleteCoachTouchPointArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFitCoachArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeletePerformanceQualityScoreArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePlanArgs = {
  planId: Scalars['ID']['input'];
};

export type MutationDeleteUserArgs = {
  forgetIterable?: Scalars['Boolean']['input'];
  jira?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationDeleteUserGoalArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationEditActivityArgs = {
  activity: EditActivityInput;
};

export type MutationGenerateSignedUrlArgs = {
  input: SignedUrlInput;
};

export type MutationInviteFamilyMemberArgs = {
  input: InviteFamilyMemberInput;
};

export type MutationJoinChallengeArgs = {
  challengeTeamId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationJoinLiveClassArgs = {
  classId: Scalars['String']['input'];
};

export type MutationLeaveChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationLeavePracticeArgs = {
  practiceId: Scalars['ID']['input'];
};

export type MutationLeaveProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationMarkBadgesSeenForChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationMarkChatReadArgs = {
  chatId: Scalars['ID']['input'];
  lastMessageRead: Scalars['ZonedDateTime']['input'];
};

export type MutationMarkChatStarredArgs = {
  chatId: Scalars['ID']['input'];
  isStarred: Scalars['Boolean']['input'];
};

export type MutationMarkPlanItemCompleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationMarkSurveyResponseViewedArgs = {
  surveyResponseId: Scalars['ID']['input'];
};

export type MutationMarkUserStarredArgs = {
  isStarred: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationPurgeWearableDataArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationRateAppArgs = {
  feedback: FeedbackKind;
};

export type MutationRebookSessionArgs = {
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  sessionId: Scalars['ID']['input'];
  start: Scalars['ZonedDateTime']['input'];
  update?: InputMaybe<RebookSessionUpdateInput>;
  useCronofy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationRefreshContentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRegisterArgs = {
  input: RegisterUserInput;
};

export type MutationRegisterDeviceArgs = {
  app?: InputMaybe<App>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationRemindFamilyMemberArgs = {
  input: RemindFamilyMemberInput;
};

export type MutationRemoveClientArgs = {
  clientId: Scalars['ID']['input'];
};

export type MutationRemoveCoachMembersArgs = {
  clientId: Scalars['ID']['input'];
  coachId: Scalars['ID']['input'];
};

export type MutationRemoveDomainArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveEligibleEmailAddressArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveFamilyMemberArgs = {
  input: RemoveFamilyMemberInput;
};

export type MutationRemoveFromChallengeArgs = {
  challengeId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationRemoveFromChatRoomArgs = {
  chatRoomId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRemoveFromClientArgs = {
  clientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationRemoveLocationArgs = {
  locationId: Scalars['ID']['input'];
};

export type MutationRemoveReactionArgs = {
  reactionId: Scalars['ID']['input'];
};

export type MutationRemoveSavedArgs = {
  contentId: Scalars['ID']['input'];
};

export type MutationRemoveStudioArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveUserSurveysArgs = {
  surveyIdentifier: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationRepairClientUsersArgs = {
  clientId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationReportUserArgs = {
  input: ReportUserInput;
};

export type MutationResetPreviewArgs = {
  extension?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationResetProgressArgs = {
  contentId?: InputMaybe<Scalars['ID']['input']>;
  sectionId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationResyncIterableUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationSaveWearableSamplesArgs = {
  data: WearableSamplesInput;
};

export type MutationSendHistoricalMessageArgs = {
  chatId: Scalars['ID']['input'];
  createdAt: Scalars['ZonedDateTime']['input'];
  text: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationSendMessageArgs = {
  chatId: Scalars['ID']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['ID']['input']>;
  optimisticId?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

export type MutationSendSupportRequestArgs = {
  app: App;
  type: SupportRequestType;
};

export type MutationSetMinBuildNumberArgs = {
  input: SetMinBuildNumberInput;
};

export type MutationSetModalStatusArgs = {
  status: SetModalStatus;
};

export type MutationSetNotificationOptionsArgs = {
  input: Array<NotificationOptionInput>;
};

export type MutationSetSessionCreditsArgs = {
  input: SessionCreditsInput;
};

export type MutationSetUnitsPreferenceArgs = {
  input: UnitsInput;
};

export type MutationStartPracticeArgs = {
  practiceId: Scalars['ID']['input'];
};

export type MutationStartProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationSyncEligibleEmailAddressArgs = {
  input: SyncEligibleEmailAddressInput;
};

export type MutationSyncMboMembershipsArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationSyncWearableSamplesArgs = {
  data: WearableSamplesInput;
};

export type MutationTemporaryBackfillSleepOverviewArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  limit: Scalars['Int']['input'];
};

export type MutationTriggerIterablePushArgs = {
  campaignId: Scalars['Int']['input'];
  dataFields?: InputMaybe<Scalars['JSONObject']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationUnforgetIterableUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationUnforgetUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationUpdateAttendanceArgs = {
  attendance: CoachSessionAttendance;
  sessionId: Scalars['ID']['input'];
};

export type MutationUpdateAttendedArgs = {
  attended: Scalars['Boolean']['input'];
  sessionId: Scalars['ID']['input'];
};

export type MutationUpdateAvatarArgs = {
  input: UpdateAvatarInput;
};

export type MutationUpdateChallengeArgs = {
  input: UpdateChallengeInput;
};

export type MutationUpdateChatRoomArgs = {
  input: UpdateChatRoomInput;
};

export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};

export type MutationUpdateCoachCronofyTimezoneArgs = {
  input: UpdateCoachCronofyTimezoneInput;
};

export type MutationUpdateCoachDataArgs = {
  input: UpdateCoachDataInput;
};

export type MutationUpdateCoachLocationArgs = {
  input: UpdateCoachLocationInput;
};

export type MutationUpdateCoachTouchPointArgs = {
  input: UpdateCoachTouchPointInput;
};

export type MutationUpdateDomainArgs = {
  input: UpdateDomainInput;
};

export type MutationUpdateEligibleEmailAddressArgs = {
  input: MutateEligibleEmailAddressInput;
};

export type MutationUpdateLastActivityArgs = {
  app: App;
  platform?: TokenPlatform;
};

export type MutationUpdateNoteArgs = {
  input: UpdateNoteInput;
};

export type MutationUpdateNotificationOptionArgs = {
  input: UpdateNotificationOptionInput;
};

export type MutationUpdatePerformanceQualityScoreArgs = {
  performanceQualityScore: UpdatePerformanceQualityScoreInput;
};

export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};

export type MutationUpdatePracticeArgs = {
  practiceId: Scalars['ID']['input'];
};

export type MutationUpdateProfileJsonArgs = {
  input: UpdateProfileJsonInput;
};

export type MutationUpdateProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationUpdateProgressArgs = {
  contentId: Scalars['ID']['input'];
  customPlanItemId?: InputMaybe<Scalars['ID']['input']>;
  progress?: InputMaybe<Scalars['Float']['input']>;
  sectionId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateStudioArgs = {
  input: UpdateStudioInput;
};

export type MutationUpdateUnreadCountArgs = {
  chatId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserGoalArgs = {
  input: UpdateUserGoalInput;
};

export type MutationUpdateWelcomeSectionDisplayArgs = {
  display: WelcomeSectionDisplay;
};

export type NewMessagePayload = {
  __typename?: 'NewMessagePayload';
  chatRoom: ChatRoom;
  message: Message;
  optimisticId?: Maybe<Scalars['ID']['output']>;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type NotHello = {
  __typename?: 'NotHello';
  not?: Maybe<Scalars['String']['output']>;
};

export type Note = Node & {
  __typename?: 'Note';
  coach?: Maybe<User>;
  content: Scalars['String']['output'];
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  lastUpdatedBy?: Maybe<User>;
  member: User;
  updatedAt: Scalars['ZonedDateTime']['output'];
};

export enum NotificationKind {
  Push = 'push',
}

export type NotificationOption = Node & {
  __typename?: 'NotificationOption';
  app: App;
  description: Scalars['String']['output'];
  featureFlag?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  iterableId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  push: Scalars['Boolean']['output'];
  visible: Scalars['Boolean']['output'];
};

export type NotificationOptionConnection = Connection & {
  __typename?: 'NotificationOptionConnection';
  count: Scalars['Int']['output'];
  edges: Array<NotificationOptionEdge>;
  nodes: Array<NotificationOption>;
  pageInfo: PageInfo;
};

export type NotificationOptionEdge = Edge & {
  __typename?: 'NotificationOptionEdge';
  cursor: Scalars['String']['output'];
  node: NotificationOption;
};

export type NotificationOptionInput = {
  optionId: Scalars['String']['input'];
  push?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NumberAnswer = {
  __typename?: 'NumberAnswer';
  numberValue: Scalars['Float']['output'];
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum Os {
  Android = 'android',
  Ios = 'ios',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['String']['output'];
};

export type ParticipantsFilter = {
  appendSelf?: InputMaybe<Scalars['Boolean']['input']>;
  challengeTeamId?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  excludeUsersWithoutAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  excludeUsersWithoutScore?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ParticipantsOrder {
  CreatedAt = 'createdAt',
  FirstName = 'firstName',
  LastName = 'lastName',
  Score = 'score',
}

export type ParticipantsOrderBy = {
  direction: OrderDirection;
  order: ParticipantsOrder;
};

export enum PerformanceQuality {
  BodyComposition = 'BodyComposition',
  Curiosity = 'Curiosity',
  Endurance = 'Endurance',
  FvProfile = 'FVProfile',
  Flowability = 'Flowability',
  FuelUtilization = 'FuelUtilization',
  Grit = 'Grit',
  InnerAwareness = 'InnerAwareness',
  LoadAccumulation = 'LoadAccumulation',
  MovementEfficiency = 'MovementEfficiency',
  NutrientProfile = 'NutrientProfile',
  SelfRegulation = 'SelfRegulation',
  SleepProficiency = 'SleepProficiency',
}

export type PerformanceQualityConnection = Connection & {
  __typename?: 'PerformanceQualityConnection';
  count: Scalars['Int']['output'];
  edges: Array<PerformanceQualityEdge>;
  nodes: Array<PerformanceQualityScore>;
  pageInfo: PageInfo;
};

export type PerformanceQualityEdge = Edge & {
  __typename?: 'PerformanceQualityEdge';
  cursor: Scalars['String']['output'];
  node: PerformanceQualityScore;
};

export type PerformanceQualityScore = Node & {
  __typename?: 'PerformanceQualityScore';
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  performanceQuality: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  subComponent: Scalars['String']['output'];
};

export type Photo = Node & {
  __typename?: 'Photo';
  basePath: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  preview?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type PhotoUrlArgs = {
  circle?: InputMaybe<Scalars['Boolean']['input']>;
  extension?: InputMaybe<Scalars['String']['input']>;
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type PhotoAnswer = {
  __typename?: 'PhotoAnswer';
  caption?: Maybe<Scalars['String']['output']>;
  photo: Photo;
};

export type PhysicalActivity = Activity &
  Node &
  TimeBasedActivity & {
    __typename?: 'PhysicalActivity';
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    distance?: Maybe<Distance>;
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<ActivityGenre>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type Plan = Node & {
  __typename?: 'Plan';
  endDate: Scalars['LocalDate']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  planItems: PlanItemConnection;
  startDate: Scalars['LocalDate']['output'];
  targetedPerformanceQualities: Array<PerformanceQuality>;
};

export type PlanPlanItemsArgs = {
  filter?: InputMaybe<PlanItemsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type PlanItem = {
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  date: Scalars['LocalDate']['output'];
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  type: PlanItemType;
};

export type PlanItemConnection = Connection & {
  __typename?: 'PlanItemConnection';
  count: Scalars['Int']['output'];
  edges: Array<PlanItemEdge>;
  nodes: Array<PlanItem>;
  pageInfo: PageInfo;
};

export type PlanItemEdge = Edge & {
  __typename?: 'PlanItemEdge';
  cursor: Scalars['String']['output'];
  node: PlanItem;
};

export enum PlanItemType {
  CustomTask = 'CustomTask',
  ExosContent = 'ExosContent',
}

export type PlanItemsFilter = {
  localDateRange?: InputMaybe<LocalDateRange>;
};

export type PracticeParticipantConnection = Connection & {
  __typename?: 'PracticeParticipantConnection';
  count: Scalars['Int']['output'];
  edges: Array<PracticeParticipantsEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type PracticeParticipantsEdge = Edge & {
  __typename?: 'PracticeParticipantsEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type PracticeParticipantsFilter = {
  excludeUsersWithoutAvatar?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PracticeProgram = Content &
  Node & {
    __typename?: 'PracticeProgram';
    author?: Maybe<Scalars['String']['output']>;
    completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    congratulations?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    createdAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use subtitle now */
    eyebrow?: Maybe<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    participants: PracticeParticipantConnection;
    progress: Scalars['Float']['output'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    sections: ContentCollectionSectionsConnection;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    skills: Array<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type PracticeProgramParticipantsArgs = {
  filter?: InputMaybe<PracticeParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type PracticeProgramRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type PracticeProgramSectionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type PracticeProgramFilter = {
  status?: InputMaybe<PracticeProgramStatus>;
};

export enum PracticeProgramStatus {
  All = 'All',
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export enum Prefix {
  Avatar = 'avatar',
  Challenge = 'challenge',
  Client = 'client',
  Coach = 'coach',
  Uploads = 'uploads',
}

export type Principal = MachineClient | User;

export type Program = Content &
  Node & {
    __typename?: 'Program';
    author?: Maybe<Scalars['String']['output']>;
    completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    congratulations?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    createdAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    currentSection?: Maybe<ContentSection>;
    equipment: Array<Scalars['String']['output']>;
    /** @deprecated use subtitle now */
    eyebrow?: Maybe<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    participants: ProgramParticipantConnection;
    progress: Scalars['Float']['output'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    sections: ContentCollectionSectionsConnection;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    skills: Array<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type ProgramParticipantsArgs = {
  filter?: InputMaybe<ProgramParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type ProgramRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ProgramSectionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ProgramParticipantConnection = Connection & {
  __typename?: 'ProgramParticipantConnection';
  count: Scalars['Int']['output'];
  edges: Array<ProgramParticipantsEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ProgramParticipantsEdge = Edge & {
  __typename?: 'ProgramParticipantsEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type ProgramParticipantsFilter = {
  excludeUsersWithoutAvatar?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProgramsConnection = Connection & {
  __typename?: 'ProgramsConnection';
  count: Scalars['Int']['output'];
  edges: Array<ProgramsEdge>;
  nodes: Array<Program>;
  pageInfo: PageInfo;
};

export type ProgramsEdge = Edge & {
  __typename?: 'ProgramsEdge';
  cursor: Scalars['String']['output'];
  node: Program;
};

export type PurgeWearableDataPayload = {
  __typename?: 'PurgeWearableDataPayload';
  user: User;
};

export type Query = {
  __typename?: 'Query';
  activity?: Maybe<Activity>;
  activityGenre?: Maybe<ActivityGenre>;
  activityGenres: Array<ActivityGenre>;
  /** @deprecated No longer supported */
  article?: Maybe<Article>;
  /** @deprecated No longer supported */
  articles: ArticlesConnection;
  assessment?: Maybe<AssessmentAssignment>;
  auditLogs: AuditLogsConnection;
  availableAssessments: AssessmentSurveysConnection;
  challenge?: Maybe<Challenge>;
  challengeTeam?: Maybe<ChallengeTeam>;
  challenges: RootChallengesConnection;
  chatRoom?: Maybe<ChatRoom>;
  chatRooms: RootChatRoomsConnection;
  client?: Maybe<Client>;
  clients: RootClientsConnection;
  coachLocation?: Maybe<CoachLocation>;
  coachLocations: CoachLocationsConnection;
  coachSession?: Maybe<CoachSession>;
  content?: Maybe<Content>;
  contentCollection?: Maybe<ContentCollection>;
  contentEquipment: Array<Equipment>;
  contentGenres: Array<Genre>;
  contentIntensities: Array<ContentAttribute>;
  contentLevels: Array<Level>;
  contentProjects: Array<ContentAttribute>;
  contentSplits: Array<Split>;
  contents: ContentConnection;
  countries: Array<Country>;
  customPlan: Plan;
  domain?: Maybe<Domain>;
  domains: DomainConnection;
  eligibleEmailAddress?: Maybe<EligibleEmailAddress>;
  eligibleEmailAddresses: RootEligibleEmailAddressesConnection;
  gameplanComponents: Array<ContentAttribute>;
  gameplanSubcomponents: Array<ContentAttribute>;
  getModalStatus: ModalStatus;
  hello: Scalars['String']['output'];
  /** @deprecated No longer supported */
  home: ContentConnection;
  manyChallenges: Array<Challenge>;
  manyChatRooms: Array<ChatRoom>;
  manyClients: Array<Client>;
  manyCoachLocations: Array<CoachLocation>;
  manyDomains: Array<Domain>;
  manyEligibleEmailAddresses: Array<EligibleEmailAddress>;
  manyStudios: Array<Studio>;
  manyUsers: Array<User>;
  mboClass?: Maybe<MboClass>;
  /** @deprecated No longer supported */
  notHello?: Maybe<NotHello>;
  notificationOption: NotificationOption;
  notificationOptions: NotificationOptionConnection;
  performanceQualities: Array<ContentAttribute>;
  performanceQualityScores: PerformanceQualityConnection;
  planItem: PlanItem;
  programs: ProgramsConnection;
  self?: Maybe<User>;
  studio?: Maybe<Studio>;
  studios: StudiosConnection;
  survey?: Maybe<Survey>;
  surveyTemplate?: Maybe<SurveyTemplate>;
  systemPreferences: SystemPreferences;
  user?: Maybe<User>;
  userActivity?: Maybe<UserActivity>;
  userAssessmentSurvey: UserAssessmentSurvey;
  users: RootUsersConnection;
};

export type QueryActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryActivityGenreArgs = {
  id: Scalars['ID']['input'];
};

export type QueryActivityGenresArgs = {
  filter?: InputMaybe<ActivityGenresFilter>;
};

export type QueryArticleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAssessmentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryAvailableAssessmentsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryChallengeTeamArgs = {
  id: Scalars['ID']['input'];
};

export type QueryChallengesArgs = {
  filter?: InputMaybe<ChallengeFilter>;
  input?: InputMaybe<ConnectionInput>;
  sort?: InputMaybe<ChallengeSort>;
};

export type QueryChatRoomArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryChatRoomsArgs = {
  filter?: InputMaybe<ChatRoomsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ChatRoomsOrderBy>>;
};

export type QueryClientArgs = {
  domain?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryClientsArgs = {
  filter?: InputMaybe<ClientsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryCoachLocationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCoachLocationsArgs = {
  filter?: InputMaybe<CoachLocationsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryCoachSessionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryContentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryContentCollectionArgs = {
  slug: Scalars['String']['input'];
};

export type QueryContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<ContentOrderBy>;
};

export type QueryCustomPlanArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDomainArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDomainsArgs = {
  filter?: InputMaybe<DomainsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryEligibleEmailAddressArgs = {
  details?: InputMaybe<EligibleEmailAddressDetailsInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEligibleEmailAddressesArgs = {
  filter?: InputMaybe<EligibleEmailAddressesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryGetModalStatusArgs = {
  id: Scalars['ID']['input'];
};

export type QueryManyChallengesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyChatRoomsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyClientsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyCoachLocationsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyDomainsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyEligibleEmailAddressesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyStudiosArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyUsersArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryMboClassArgs = {
  input: MboClassDetailsInput;
};

export type QueryNotificationOptionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryNotificationOptionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryPerformanceQualityScoresArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryPlanItemArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProgramsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryStudioArgs = {
  id: Scalars['ID']['input'];
};

export type QueryStudiosArgs = {
  filter?: InputMaybe<StudiosFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QuerySurveyArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySurveyTemplateArgs = {
  id: Scalars['ID']['input'];
  scope?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  mboId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUserAssessmentSurveyArgs = {
  surveyContentId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type Question = {
  __typename?: 'Question';
  answer?: Maybe<Answer>;
  choices: Array<MultipleChoiceOption>;
  id: Scalars['ID']['output'];
  questionSubtype?: Maybe<QuestionSubtype>;
  questionType: QuestionType;
  required: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
};

export enum QuestionSubtype {
  Multiselect = 'Multiselect',
  OpenTextBox = 'OpenTextBox',
  PhotoUpload = 'PhotoUpload',
  Radio = 'Radio',
  SingleLine = 'SingleLine',
}

export enum QuestionType {
  MultipleChoice = 'MultipleChoice',
  PhotoUpload = 'PhotoUpload',
  TextEntry = 'TextEntry',
}

export type RateAppPayload = {
  __typename?: 'RateAppPayload';
  user?: Maybe<User>;
};

export type Reaction = {
  __typename?: 'Reaction';
  id: Scalars['ID']['output'];
  kind: ReactionKind;
  user: User;
};

export enum ReactionKind {
  Clap = 'clap',
  Fire = 'fire',
  Grimacing = 'grimacing',
  Laugh = 'laugh',
  RatingNegative = 'rating_negative',
  RatingPositive = 'rating_positive',
  Smile = 'smile',
  Thumbsup = 'thumbsup',
}

export type RebookSessionUpdateInput = {
  duration: Scalars['Int']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
  sessionStyle: CoachSessionStyle;
  sessionType: CoachSessionType;
};

export type RefreshContentPayload = {
  __typename?: 'RefreshContentPayload';
  content?: Maybe<Content>;
};

export type RegisterUserInput = {
  consent?: InputMaybe<Scalars['Boolean']['input']>;
  consentDate?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  marketingOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  shareDataWithEmployer?: InputMaybe<Scalars['Boolean']['input']>;
  shareEEPData?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  user?: Maybe<User>;
};

export type RemindFamilyMemberInput = {
  eligibleEmailAddressId: Scalars['ID']['input'];
};

export type RemoveFamilyMemberInput = {
  eligibleEmailAddressId: Scalars['ID']['input'];
};

export type RepairClientUsersPayload = {
  __typename?: 'RepairClientUsersPayload';
  processed: Scalars['Int']['output'];
  remaining: Scalars['Int']['output'];
};

export type ReportUserInput = {
  chatRoomId?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  surveyResponseId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type RequiredLocalDateRange = {
  end: Scalars['LocalDate']['input'];
  start: Scalars['LocalDate']['input'];
};

export type ResetProgressPayload = {
  __typename?: 'ResetProgressPayload';
  contentEdge?: Maybe<ContentEdge>;
  section?: Maybe<ContentSection>;
};

export type RootChallengesConnection = {
  __typename?: 'RootChallengesConnection';
  count: Scalars['Int']['output'];
  edges: Array<Maybe<ChallengeEdge>>;
  nodes: Array<Challenge>;
  pageInfo: PageInfo;
};

export type RootChatRoomsConnection = Connection & {
  __typename?: 'RootChatRoomsConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootChatRoomsEdge>;
  nodes: Array<ChatRoom>;
  pageInfo: PageInfo;
};

export type RootChatRoomsEdge = Edge & {
  __typename?: 'RootChatRoomsEdge';
  cursor: Scalars['String']['output'];
  node: ChatRoom;
};

export type RootClientsConnection = Connection & {
  __typename?: 'RootClientsConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootClientsEdge>;
  nodes: Array<Client>;
  pageInfo: PageInfo;
};

export type RootClientsEdge = Edge & {
  __typename?: 'RootClientsEdge';
  cursor: Scalars['String']['output'];
  node: Client;
};

export type RootEligibleEmailAddressesConnection = Connection & {
  __typename?: 'RootEligibleEmailAddressesConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootEligibleEmailAddressesEdge>;
  nodes: Array<EligibleEmailAddress>;
  pageInfo: PageInfo;
};

export type RootEligibleEmailAddressesEdge = Edge & {
  __typename?: 'RootEligibleEmailAddressesEdge';
  cursor: Scalars['String']['output'];
  node: EligibleEmailAddress;
};

export type RootMboClassEdge = Edge & {
  __typename?: 'RootMboClassEdge';
  cursor: Scalars['String']['output'];
  node: MboClass;
};

export type RootUsersConnection = Connection & {
  __typename?: 'RootUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type RootUsersEdge = Edge & {
  __typename?: 'RootUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type SsoIdentity = {
  __typename?: 'SSOIdentity';
  connection?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
};

export type SavedPayload = {
  __typename?: 'SavedPayload';
  content: Content;
  saved: Scalars['Boolean']['output'];
};

export enum ScoreUnit {
  Activities = 'Activities',
  Kilometers = 'Kilometers',
  Miles = 'Miles',
  Minutes = 'Minutes',
}

export type SessionCreditsInput = {
  count: Scalars['Int']['input'];
  rowVersion?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};

export type SessionCreditsPayload = {
  __typename?: 'SessionCreditsPayload';
  count: Scalars['Int']['output'];
  rowVersion: Scalars['Int']['output'];
  userId: Scalars['ID']['output'];
};

export type SetMinBuildNumberInput = {
  app: App;
  buildNumber: Scalars['Int']['input'];
  os: Os;
};

export type SetModalStatus = {
  id: Scalars['ID']['input'];
  modalSeen: Scalars['Boolean']['input'];
};

export type SetNotificationOptionsPayload = {
  __typename?: 'SetNotificationOptionsPayload';
  notificationOptions: Array<NotificationOption>;
};

export type Share = {
  __typename?: 'Share';
  message?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SignedUrlInput = {
  contentType: Scalars['String']['input'];
  prefix: Prefix;
};

export type SignedUrlPayload = {
  __typename?: 'SignedUrlPayload';
  key: Scalars['String']['output'];
  photo: Photo;
  signedUrl: Scalars['String']['output'];
};

export type SimpleContentConnection = {
  count: Scalars['Int']['output'];
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type SimpleContentEdge = {
  cursor: Scalars['String']['output'];
  node: Content;
};

export type SleepActivity = Activity &
  Node &
  TimeBasedActivity & {
    __typename?: 'SleepActivity';
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heartRate?: Maybe<HeartRate>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    sleepEfficiency?: Maybe<Scalars['Float']['output']>;
    sleepOverview: SleepOverview;
    source?: Maybe<Scalars['JSONObject']['output']>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type SleepOverview = {
  __typename?: 'SleepOverview';
  asleep: Scalars['Int']['output'];
  awake: Scalars['Int']['output'];
  inbed: Scalars['Int']['output'];
};

export type Split = {
  __typename?: 'Split';
  children: Array<Split>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Split>;
  value: Scalars['String']['output'];
};

export type StartPracticePayload = {
  __typename?: 'StartPracticePayload';
  assignedPractice: AssignedPractice;
};

export type StartProgramPayload = {
  __typename?: 'StartProgramPayload';
  assignedProgram: AssignedProgram;
};

export type StringAnswer = {
  __typename?: 'StringAnswer';
  stringValue: Scalars['String']['output'];
};

export type Studio = Node & {
  __typename?: 'Studio';
  auditLogs: AuditLogsConnection;
  client: Client;
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  isCentral: Scalars['Boolean']['output'];
  isVirtual: Scalars['Boolean']['output'];
  locations: CoachLocationsConnection;
  mboAccessToken?: Maybe<Scalars['String']['output']>;
  mboServiceId: Scalars['String']['output'];
  mboStudioId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  requiredFields: Array<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
};

export type StudioAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type StudioLocationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type StudioEdge = Edge & {
  __typename?: 'StudioEdge';
  cursor: Scalars['String']['output'];
  node: Studio;
};

export type StudiosConnection = Connection & {
  __typename?: 'StudiosConnection';
  count: Scalars['Int']['output'];
  edges: Array<StudioEdge>;
  nodes: Array<Studio>;
  pageInfo: PageInfo;
};

export type StudiosFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  chatRoomUpdate: ChatRoom;
  newMessages: NewMessagePayload;
  newTotalUnreadMessageCount: Scalars['Int']['output'];
  totalUnreadMessageCount: TotalUnreadMessageCountPayload;
};

export type SubscriptionChatRoomUpdateArgs = {
  chatId?: InputMaybe<Scalars['ID']['input']>;
};

export type SubscriptionNewMessagesArgs = {
  chatId?: InputMaybe<Scalars['ID']['input']>;
};

export type SubscriptionNewTotalUnreadMessageCountArgs = {
  apps?: InputMaybe<Array<App>>;
  kinds?: InputMaybe<Array<ChatRoomKind>>;
};

export type SubscriptionTotalUnreadMessageCountArgs = {
  fromCoachedUsers?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SupportRequestType {
  DataAccess = 'DATA_ACCESS',
  DataDeletion = 'DATA_DELETION',
}

export type Survey = Node & {
  __typename?: 'Survey';
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  responses: Array<SurveyResponse>;
  scope?: Maybe<Scalars['String']['output']>;
  surveyIdentifier: Scalars['String']['output'];
  surveyName?: Maybe<Scalars['String']['output']>;
  template?: Maybe<SurveyTemplate>;
  user: User;
};

export type SurveyFilter = {
  surveyGroup?: InputMaybe<SurveyGroup>;
  surveyIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export enum SurveyGroup {
  Assessment = 'assessment',
  Basics = 'basics',
  Motivations = 'motivations',
}

export type SurveyResponse = Node & {
  __typename?: 'SurveyResponse';
  id: Scalars['ID']['output'];
  numericValue?: Maybe<Scalars['Float']['output']>;
  question: Scalars['String']['output'];
  questionIdentifier: Scalars['String']['output'];
  response: Answer;
  subcategory?: Maybe<Scalars['String']['output']>;
  survey: Survey;
  viewedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type SurveyResponseInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  choices?: InputMaybe<Array<Scalars['String']['input']>>;
  numberAnswer?: InputMaybe<Scalars['Float']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
  question: Scalars['String']['input'];
  questionIdentifier: Scalars['String']['input'];
  scores?: InputMaybe<Array<Scalars['Int']['input']>>;
  selectedChoices?: InputMaybe<Array<Scalars['Int']['input']>>;
  stringAnswer?: InputMaybe<Scalars['String']['input']>;
};

export type SurveyResponsePayload = {
  __typename?: 'SurveyResponsePayload';
  surveyResponse: SurveyResponse;
};

export type SurveyTemplate = {
  __typename?: 'SurveyTemplate';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  questions: Array<Question>;
};

export type SyncEligibleEmailAddressInput = {
  clientId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  employeeId: Scalars['String']['input'];
};

export type SyncMboMembershipsPayload = {
  __typename?: 'SyncMboMembershipsPayload';
  user: User;
};

export type SystemPreferences = {
  __typename?: 'SystemPreferences';
  minFitAndroidBuild: Scalars['Int']['output'];
  minFitIosBuild: Scalars['Int']['output'];
  minPerformAndroidBuild: Scalars['Int']['output'];
  minPerformIosBuild: Scalars['Int']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String']['output'];
};

export type TimeBasedActivity = {
  duration?: Maybe<Scalars['Int']['output']>;
  endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
  startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type Token = {
  __typename?: 'Token';
  accessToken: Scalars['String']['output'];
  expiresIn: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export enum TokenPlatform {
  Mobile = 'mobile',
  Web = 'web',
}

export type TotalUnreadMessageCountPayload = {
  __typename?: 'TotalUnreadMessageCountPayload';
  count: Scalars['Int']['output'];
};

export type TouchPointFilter = {
  dueRange?: InputMaybe<DateRange>;
  includeCompletedAfter?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  status?: InputMaybe<Array<CoachTouchPointStatus>>;
};

export type TouchPointsOrderBy = {
  direction: OrderDirection;
  order: CoachTouchPointOrder;
};

export type TriggerIterablePushPayload = {
  __typename?: 'TriggerIterablePushPayload';
  success: Scalars['Boolean']['output'];
};

export type UnforgetUserPayload = {
  __typename?: 'UnforgetUserPayload';
  userId: Scalars['ID']['output'];
};

export type UnitsInput = {
  unitsPreference: UnitsPreference;
};

export type UnitsPayload = {
  __typename?: 'UnitsPayload';
  user: User;
};

export enum UnitsPreference {
  Imperial = 'imperial',
  Metric = 'metric',
}

export type UpdateAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  addressLine4?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  localityOrCity?: InputMaybe<Scalars['String']['input']>;
  postalOrZipCode?: InputMaybe<Scalars['String']['input']>;
  stateOrDistrictOrProvince?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAvatarInput = {
  avatarId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAvatarPayload = {
  __typename?: 'UpdateAvatarPayload';
  user: User;
};

export type UpdateChallengeInput = {
  backgroundId?: InputMaybe<Scalars['String']['input']>;
  badgeId?: InputMaybe<Scalars['String']['input']>;
  challengeType?: InputMaybe<ChallengeType>;
  clientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  clientSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  distanceUnit?: InputMaybe<DistanceUnit>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['LocalDate']['input']>;
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  goal?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  qualifyingGenres?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate?: InputMaybe<Scalars['LocalDate']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  teams?: InputMaybe<Array<UpdateChallengeTeamInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateChallengeTeamInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateChatRoomInput = {
  app?: InputMaybe<App>;
  id: Scalars['ID']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateClientInput = {
  calendarPlatform?: InputMaybe<ClientCalendarPlatform>;
  collectsMemberData?: InputMaybe<Scalars['Boolean']['input']>;
  collectsMemberDataForEEP?: InputMaybe<Scalars['Boolean']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  domainIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  inactivityDuration?: InputMaybe<Scalars['Duration']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  logoId?: InputMaybe<Scalars['String']['input']>;
  maxFamilyMembers?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  needsUsToFixTheirUserNames?: InputMaybe<Scalars['Boolean']['input']>;
  ssoConnection?: InputMaybe<Scalars['String']['input']>;
  strictAppCheck?: InputMaybe<Scalars['Boolean']['input']>;
  usesEligibilityServiceType?: InputMaybe<Scalars['Boolean']['input']>;
  usesFitnessCenterForEligibility?: InputMaybe<Scalars['Boolean']['input']>;
  usesGlobalSignOut?: InputMaybe<Scalars['Boolean']['input']>;
  usesHighPerformanceCoaching?: InputMaybe<Scalars['Boolean']['input']>;
  usesPersonalTraining?: InputMaybe<Scalars['Boolean']['input']>;
  videoPlatform?: InputMaybe<ClientVideoPlatform>;
};

export type UpdateCoachCronofyTimezoneInput = {
  coachId?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['ZoneId']['input'];
};

export type UpdateCoachCronofyTimezoneResponse = {
  __typename?: 'UpdateCoachCronofyTimezoneResponse';
  user: User;
};

export type UpdateCoachDataInput = {
  availableForMemberAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bodyPhotoId?: InputMaybe<Scalars['String']['input']>;
  calendlyUrl?: InputMaybe<Scalars['String']['input']>;
  clientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  coachingStyle?: InputMaybe<CoachingStyle>;
  dailySessionLimit?: InputMaybe<Scalars['Int']['input']>;
  degrees?: InputMaybe<Array<Scalars['String']['input']>>;
  enableCalendarBuffer?: InputMaybe<Scalars['Boolean']['input']>;
  headshotPhotoId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  inOfficeDays?: InputMaybe<Array<Scalars['Int']['input']>>;
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  mboStaffId?: InputMaybe<Scalars['String']['input']>;
  passions?: InputMaybe<Array<Scalars['String']['input']>>;
  supportedCountries?: InputMaybe<Array<Scalars['String']['input']>>;
  supportedGoals?: InputMaybe<Array<UserGoalType>>;
  titles?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<CoachType>;
};

export type UpdateCoachDataPayload = {
  __typename?: 'UpdateCoachDataPayload';
  user: User;
};

export type UpdateCoachLocationInput = {
  id: Scalars['ID']['input'];
  isHeadquarters?: InputMaybe<Scalars['Boolean']['input']>;
  mboLocationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  studioId: Scalars['String']['input'];
};

export type UpdateCoachTouchPointInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  due?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  id: Scalars['ID']['input'];
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDomainInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateEmergencyContactInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMboBookingsPayload = {
  __typename?: 'UpdateMboBookingsPayload';
  remainingBookings: Scalars['Int']['output'];
};

export type UpdateNoteInput = {
  content: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
};

export type UpdateNotificationOptionInput = {
  app?: InputMaybe<App>;
  description?: InputMaybe<Scalars['String']['input']>;
  featureFlag?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  iterableId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePerformanceQualityScoreInput = {
  component?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  performanceQuality?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  subComponent?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePlanInput = {
  /** If true, will allow admin users in the dev environment to override start date restrictions */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['ID']['input'];
  planItems?: InputMaybe<Array<UpdatePlanItemInput>>;
  startDate?: InputMaybe<Scalars['LocalDate']['input']>;
  targetedPerformanceQualities?: InputMaybe<Array<PerformanceQuality>>;
};

export type UpdatePlanItemInput = {
  component?: InputMaybe<Scalars['String']['input']>;
  contentId?: InputMaybe<Scalars['ID']['input']>;
  date?: InputMaybe<Scalars['LocalDate']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PlanItemType>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePlanPayload = {
  __typename?: 'UpdatePlanPayload';
  plan: Plan;
};

export type UpdatePracticePayload = {
  __typename?: 'UpdatePracticePayload';
  assignedPractice: AssignedPractice;
};

export type UpdateProfileJsonInput = {
  profileJson?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type UpdateProfileJsonPayload = {
  __typename?: 'UpdateProfileJsonPayload';
  user: User;
};

export type UpdateProgramPayload = {
  __typename?: 'UpdateProgramPayload';
  assignedProgram: AssignedProgram;
};

export type UpdateStudioInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isCentral?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  mboServiceId?: InputMaybe<Scalars['String']['input']>;
  mboStudioId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserGoalInput = {
  coachNote?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  type: UserGoalType;
};

export type UpdateUserGoalPayload = {
  __typename?: 'UpdateUserGoalPayload';
  user: User;
};

export type UpdateUserInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<UpdateAddressInput>;
  birthday?: InputMaybe<Scalars['LocalDate']['input']>;
  clientRelationship?: InputMaybe<UserClientRelationship>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emergencyContact?: InputMaybe<UpdateEmergencyContactInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fitClientId?: InputMaybe<Scalars['ID']['input']>;
  hasHighPerformanceCoaching?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identity?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isCoach?: InputMaybe<Scalars['Boolean']['input']>;
  isConcierge?: InputMaybe<Scalars['Boolean']['input']>;
  isMegaAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  isReadOnlyAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isSalesTeam?: InputMaybe<Scalars['Boolean']['input']>;
  isTestUser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  officeLocation?: InputMaybe<Scalars['ID']['input']>;
  pronoun?: InputMaybe<Scalars['String']['input']>;
  registered?: InputMaybe<Scalars['Boolean']['input']>;
  shareDataWithEmployer?: InputMaybe<Scalars['Boolean']['input']>;
  shareEEPData?: InputMaybe<Scalars['Boolean']['input']>;
  workingStyle?: InputMaybe<WorkingStyle>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user: User;
};

export type User = Node & {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  activity: ActivityConnection;
  activityAggregates: ActivityAggregates;
  age?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Performs poorly and doesn't serve our use cases well. Prefer the app field on a chat or other object which is actually related to the interaction you're interested in. */
  app: App;
  assessmentSurveys: UserAssessmentSurveysConnection;
  assignedPrograms: UserAssignedProgramsConnection;
  auditLogs: AuditLogsConnection;
  authHistory: UserAuthHistoryConnection;
  availableClasses: UserAvailableClassesConnection;
  avatar?: Maybe<Photo>;
  birthday?: Maybe<Scalars['LocalDate']['output']>;
  blockedUsers: UserBlockedUsersConnection;
  bookedClasses: UserBookingsConnection;
  /** Creates or returns the chat between the user and the selected bot. Returns null if the user does not have access to the bot in question. */
  botChat?: Maybe<ChatRoom>;
  /** Tells the type of the bot. Returns null if the user is not a bot. */
  botKind?: Maybe<BotKind>;
  canShareData: Scalars['Boolean']['output'];
  challenges: UserChallengesConnection;
  chatRooms: UserChatRoomsConnection;
  clientRelationship?: Maybe<UserClientRelationship>;
  /** @deprecated use fitClient instead */
  clients: UserClientsConnection;
  coachData?: Maybe<CoachData>;
  coachSessionCredits?: Maybe<CoachSessionCredits>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ZonedDateTime']['output'];
  currentCustomPlan?: Maybe<Plan>;
  currentPractice?: Maybe<AssignedPractice>;
  currentProgram?: Maybe<AssignedProgram>;
  customPlans: UserPlansConnection;
  customPlansActiveDays: Array<ActiveCustomPlanDay>;
  devices: Array<Device>;
  eligibleEmailAddress?: Maybe<EligibleEmailAddress>;
  email: Scalars['String']['output'];
  emergencyContactDetails?: Maybe<EmergencyContactDetails>;
  familyInvitations: Array<EligibleEmailAddress>;
  firstName?: Maybe<Scalars['String']['output']>;
  fitClient?: Maybe<Client>;
  fitCoach?: Maybe<User>;
  fitCoachChat?: Maybe<ChatRoom>;
  goal?: Maybe<UserGoal>;
  hasHighPerformanceCoaching: Scalars['Boolean']['output'];
  hasInPersonMemberships: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  identity?: Maybe<Scalars['String']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  isBlocked: Scalars['Boolean']['output'];
  isCoach: Scalars['Boolean']['output'];
  isConcierge: Scalars['Boolean']['output'];
  isFamilyMember: Scalars['Boolean']['output'];
  isMegaAdmin: Scalars['Boolean']['output'];
  isPrivate: Scalars['Boolean']['output'];
  isReadOnlyAdmin: Scalars['Boolean']['output'];
  isSalesTeam: Scalars['Boolean']['output'];
  isStarred: Scalars['Boolean']['output'];
  isTestUser: Scalars['Boolean']['output'];
  iterableToken: Token;
  lastActivity: LastActivity;
  lastName?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Note>;
  notes: UserNotesConnection;
  notificationOptions: Array<NotificationOption>;
  officeLocation?: Maybe<CoachLocation>;
  practicePrograms: UserPracticeProgramsConnection;
  profileComplete: Scalars['Boolean']['output'];
  profileJson?: Maybe<Scalars['JSONObject']['output']>;
  pronouns?: Maybe<Scalars['String']['output']>;
  registered: Scalars['Boolean']['output'];
  savedContent: ContentSavedConnection;
  section?: Maybe<ContentSection>;
  sessions: UserCoachSessionsConnection;
  share?: Maybe<Share>;
  shareDataWithEmployer: Scalars['Boolean']['output'];
  shareEEPData: Scalars['Boolean']['output'];
  shouldSeeRateAppModal: Scalars['Boolean']['output'];
  studios: UserStudiosConnection;
  surveys: UserSurveysConnection;
  token?: Maybe<Scalars['JSONObject']['output']>;
  unitsPreference: UnitsPreference;
  userActivity: UserActivityConnection;
  userActivityTrend: Array<UserActivitySummary>;
  wearableSamples: WearableSamplesConnection;
  wearableSamplesLastSyncedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  /** @deprecated Removed welcome section */
  welcomeSectionDisplay: WelcomeSectionDisplay;
  workingStyle?: Maybe<WorkingStyle>;
};

export type UserActivityArgs = {
  filter?: InputMaybe<ActivityFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserActivityAggregatesArgs = {
  dateRange?: InputMaybe<DateRange>;
};

export type UserAssessmentSurveysArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserAssignedProgramsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserAvailableClassesArgs = {
  filter: MboClassFilter;
  input?: InputMaybe<ConnectionInput>;
};

export type UserBlockedUsersArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserBookedClassesArgs = {
  filter: MboBookingsFilter;
  input?: InputMaybe<ConnectionInput>;
};

export type UserBotChatArgs = {
  bot: BotKind;
};

export type UserChallengesArgs = {
  filter?: InputMaybe<ChallengesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserChatRoomsArgs = {
  filters?: InputMaybe<ChatRoomsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ChatRoomsOrderBy>>;
};

export type UserClientsArgs = {
  filter?: InputMaybe<ClientsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserCustomPlansArgs = {
  filter?: InputMaybe<CustomPlansFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserCustomPlansActiveDaysArgs = {
  filter: CustomPlanActiveDaysFilter;
};

export type UserIterableTokenArgs = {
  tokenType?: InputMaybe<TokenPlatform>;
};

export type UserNotesArgs = {
  filter?: InputMaybe<UserNotesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserNotificationOptionsArgs = {
  app: App;
};

export type UserPracticeProgramsArgs = {
  filter?: InputMaybe<PracticeProgramFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserSavedContentArgs = {
  filter?: InputMaybe<ContentFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserSectionArgs = {
  id: Scalars['String']['input'];
};

export type UserSessionsArgs = {
  dateFilter?: InputMaybe<Array<DateFilter>>;
  filter?: InputMaybe<CoachSessionFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<CoachSessionOrderBy>>;
};

export type UserStudiosArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserSurveysArgs = {
  filter?: InputMaybe<SurveyFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserUserActivityArgs = {
  filter?: InputMaybe<UserActivityFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserWearableSamplesArgs = {
  filter?: InputMaybe<WearableSamplesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserActivity = Node & {
  __typename?: 'UserActivity';
  activity?: Maybe<Activity>;
  attribution?: Maybe<ActivityAttribution>;
  attributionTitle?: Maybe<Scalars['String']['output']>;
  attributionType: UserActivityAttributionType;
  distance?: Maybe<Distance>;
  duration?: Maybe<Scalars['Int']['output']>;
  endTime: Scalars['ZonedDateTime']['output'];
  genre?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use SleepActivity.sleepEfficiency instead */
  sleepEfficiency?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<ActivitySource>;
  startTime: Scalars['ZonedDateTime']['output'];
  title: Scalars['String']['output'];
  type: UserActivityType;
};

export enum UserActivityAttributionType {
  Assessment = 'Assessment',
  Classes = 'Classes',
  Monitoring = 'Monitoring',
  Other = 'Other',
  Practice = 'Practice',
  Program = 'Program',
  Sleep = 'Sleep',
}

export type UserActivityConnection = Connection & {
  __typename?: 'UserActivityConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserActivityEdge>;
  nodes: Array<UserActivity>;
  pageInfo: PageInfo;
};

export type UserActivityEdge = Edge & {
  __typename?: 'UserActivityEdge';
  cursor: Scalars['String']['output'];
  node: UserActivity;
};

export type UserActivityFilter = {
  attributionType?: InputMaybe<Array<InputMaybe<UserActivityAttributionType>>>;
  type?: InputMaybe<Array<InputMaybe<UserActivityType>>>;
};

export type UserActivitySummary = {
  __typename?: 'UserActivitySummary';
  classes: Scalars['Int']['output'];
  date: Scalars['LocalDate']['output'];
  other: Scalars['Int']['output'];
  practice: Scalars['Int']['output'];
  program: Scalars['Int']['output'];
};

export enum UserActivityType {
  Aod = 'Aod',
  Article = 'Article',
  Assessment = 'Assessment',
  Audio = 'Audio',
  Encore = 'Encore',
  InPerson = 'InPerson',
  ManualEntry = 'ManualEntry',
  PracticeSelection = 'PracticeSelection',
  ProgramSelection = 'ProgramSelection',
  Video = 'Video',
  Virtual = 'Virtual',
  Vod = 'Vod',
  WearableGoogleFit = 'WearableGoogleFit',
  WearableHealthConnectActivity = 'WearableHealthConnectActivity',
  WearableHealthKit = 'WearableHealthKit',
  WearableHealthKitSleep = 'WearableHealthKitSleep',
}

export type UserAssessmentAssignmentEdge = Edge & {
  __typename?: 'UserAssessmentAssignmentEdge';
  cursor: Scalars['String']['output'];
  node: AssessmentAssignment;
};

export type UserAssessmentAssignmentsConnection = Connection & {
  __typename?: 'UserAssessmentAssignmentsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAssessmentAssignmentEdge>;
  nodes: Array<AssessmentAssignment>;
  pageInfo: PageInfo;
};

export type UserAssessmentAssignmentsFilter = {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserAssessmentSurvey = Node & {
  __typename?: 'UserAssessmentSurvey';
  assignments: UserAssessmentAssignmentsConnection;
  id: Scalars['ID']['output'];
  /**
   * whether or not this survey is shown to members
   * these are not directly assignable and are completed by coaches
   * @deprecated Use type instead
   */
  isMemberFacing: Scalars['Boolean']['output'];
  mnmrType?: Maybe<Scalars['String']['output']>;
  template: SurveyTemplate;
  title?: Maybe<Scalars['String']['output']>;
  type: AssessmentType;
};

export type UserAssessmentSurveyAssignmentsArgs = {
  filter?: InputMaybe<UserAssessmentAssignmentsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserAssessmentSurveyEdge = Edge & {
  __typename?: 'UserAssessmentSurveyEdge';
  cursor: Scalars['String']['output'];
  node: UserAssessmentSurvey;
};

export type UserAssessmentSurveysConnection = Connection & {
  __typename?: 'UserAssessmentSurveysConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAssessmentSurveyEdge>;
  nodes: Array<UserAssessmentSurvey>;
  pageInfo: PageInfo;
};

export type UserAssignedProgramsConnection = Connection & {
  __typename?: 'UserAssignedProgramsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAssignedProgramsEdge>;
  nodes: Array<AssignedProgram>;
  pageInfo: PageInfo;
};

export type UserAssignedProgramsEdge = Edge & {
  __typename?: 'UserAssignedProgramsEdge';
  cursor: Scalars['String']['output'];
  node: AssignedProgram;
};

export type UserAuthHistory = Node & {
  __typename?: 'UserAuthHistory';
  challengeResponses: Array<Scalars['String']['output']>;
  eventDate?: Maybe<Scalars['ZonedDateTime']['output']>;
  id: Scalars['ID']['output'];
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type UserAuthHistoryConnection = Connection & {
  __typename?: 'UserAuthHistoryConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAuthHistoryEdge>;
  nodes: Array<UserAuthHistory>;
  pageInfo: PageInfo;
};

export type UserAuthHistoryEdge = Edge & {
  __typename?: 'UserAuthHistoryEdge';
  cursor: Scalars['String']['output'];
  node: UserAuthHistory;
};

export type UserAvailabilityConnection = Connection & {
  __typename?: 'UserAvailabilityConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAvailabilityEdge>;
  nodes: Array<Calendar>;
  pageInfo: PageInfo;
};

export type UserAvailabilityEdge = Edge & {
  __typename?: 'UserAvailabilityEdge';
  cursor: Scalars['String']['output'];
  node: Calendar;
};

export type UserAvailableClassesConnection = Connection & {
  __typename?: 'UserAvailableClassesConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAvailableClassesEdge>;
  nodes: Array<MboClass>;
  pageInfo: PageInfo;
};

export type UserAvailableClassesEdge = Edge & {
  __typename?: 'UserAvailableClassesEdge';
  cursor: Scalars['String']['output'];
  node: MboClass;
};

export type UserBlockedUsersConnection = Connection & {
  __typename?: 'UserBlockedUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserBlockedUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type UserBlockedUsersEdge = Edge & {
  __typename?: 'UserBlockedUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserBookingsConnection = Connection & {
  __typename?: 'UserBookingsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserBookingsEdge>;
  nodes: Array<MboClass>;
  pageInfo: PageInfo;
};

export type UserBookingsEdge = Edge & {
  __typename?: 'UserBookingsEdge';
  cursor: Scalars['String']['output'];
  node: MboClass;
};

export type UserChallengesConnection = Connection & {
  __typename?: 'UserChallengesConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserChallengesEdge>;
  nodes: Array<Challenge>;
  pageInfo: PageInfo;
};

export type UserChallengesEdge = Edge & {
  __typename?: 'UserChallengesEdge';
  cursor: Scalars['String']['output'];
  node: Challenge;
  place?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type UserChatRoomsConnection = Connection & {
  __typename?: 'UserChatRoomsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserChatRoomsEdge>;
  nodes: Array<ChatRoom>;
  pageInfo: PageInfo;
  unreadMessageCount: Scalars['Int']['output'];
};

export type UserChatRoomsEdge = Edge & {
  __typename?: 'UserChatRoomsEdge';
  cursor: Scalars['String']['output'];
  node: ChatRoom;
};

export enum UserClientRelationship {
  Dependent = 'Dependent',
  Employee = 'Employee',
}

export type UserClientsConnection = Connection & {
  __typename?: 'UserClientsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserClientsEdge>;
  nodes: Array<Client>;
  pageInfo: PageInfo;
};

export type UserClientsEdge = Edge & {
  __typename?: 'UserClientsEdge';
  cursor: Scalars['String']['output'];
  node: Client;
};

export type UserCoachSessionsConnection = Connection & {
  __typename?: 'UserCoachSessionsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserCoachSessionsEdge>;
  nodes: Array<CoachSession>;
  pageInfo: PageInfo;
};

export type UserCoachSessionsEdge = Edge & {
  __typename?: 'UserCoachSessionsEdge';
  cursor: Scalars['String']['output'];
  node: CoachSession;
};

export type UserGoal = {
  __typename?: 'UserGoal';
  coachNote?: Maybe<Scalars['String']['output']>;
  coachNoteUpdatedBy?: Maybe<User>;
  createdAt: Scalars['ZonedDateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: UserGoalType;
  updatedAt: Scalars['ZonedDateTime']['output'];
};

export enum UserGoalType {
  Appearance = 'Appearance',
  EmotionalWellbeing = 'EmotionalWellbeing',
  EnergyLevels = 'EnergyLevels',
  Health = 'Health',
  /** @deprecated Not used */
  NotSure = 'NotSure',
  PainManagement = 'PainManagement',
  /** @deprecated Use 'PainManagement' instead */
  PainReduction = 'PainReduction',
  PhysicalPerformance = 'PhysicalPerformance',
  /** @deprecated Use 'SocialWellbeing' instead */
  Relationships = 'Relationships',
  SocialWellbeing = 'SocialWellbeing',
  /** @deprecated Use 'PhysicalPerformance' instead */
  ToneUp = 'ToneUp',
  /** @deprecated Not used */
  TryingNewThings = 'TryingNewThings',
  WorkPerformance = 'WorkPerformance',
}

export type UserNotesConnection = Connection & {
  __typename?: 'UserNotesConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserNotesEdge>;
  nodes: Array<Note>;
  pageInfo: PageInfo;
};

export type UserNotesEdge = Edge & {
  __typename?: 'UserNotesEdge';
  cursor: Scalars['String']['output'];
  node: Note;
};

export type UserNotesFilter = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserPlansConnection = Connection & {
  __typename?: 'UserPlansConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserPlansEdge>;
  nodes: Array<Plan>;
  pageInfo: PageInfo;
};

export type UserPlansEdge = Edge & {
  __typename?: 'UserPlansEdge';
  cursor: Scalars['String']['output'];
  node: Plan;
};

export type UserPracticeProgramEdge = Edge & {
  __typename?: 'UserPracticeProgramEdge';
  cursor: Scalars['String']['output'];
  node: PracticeProgram;
};

export type UserPracticeProgramsConnection = Connection & {
  __typename?: 'UserPracticeProgramsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserPracticeProgramEdge>;
  nodes: Array<PracticeProgram>;
  pageInfo: PageInfo;
};

export type UserStudiosConnection = Connection & {
  __typename?: 'UserStudiosConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserStudiosEdge>;
  nodes: Array<Studio>;
  pageInfo: PageInfo;
};

export type UserStudiosEdge = Edge & {
  __typename?: 'UserStudiosEdge';
  cursor: Scalars['String']['output'];
  node: Studio;
};

export type UserSurveysConnection = Connection & {
  __typename?: 'UserSurveysConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserSurveysEdge>;
  nodes: Array<Survey>;
  pageInfo: PageInfo;
};

export type UserSurveysEdge = Edge & {
  __typename?: 'UserSurveysEdge';
  cursor: Scalars['String']['output'];
  node: Survey;
};

export type UsersFilter = {
  clientId?: InputMaybe<Array<Scalars['String']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isCoach?: InputMaybe<Scalars['Boolean']['input']>;
  isTestUser?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Video = Content &
  Node & {
    __typename?: 'Video';
    author?: Maybe<Scalars['String']['output']>;
    brand?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    /** @deprecated use contentEquipment */
    equipment: Array<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    gameplanMetadata?: Maybe<GameplanMetadata>;
    heroPhoto?: Maybe<Photo>;
    html?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    intensity?: Maybe<Scalars['String']['output']>;
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    mediaType?: Maybe<Scalars['String']['output']>;
    mnmrType?: Maybe<Scalars['String']['output']>;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    split?: Maybe<Split>;
    subGenre?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
    type?: Maybe<Scalars['String']['output']>;
    video: Scalars['String']['output'];
  };

export type VideoRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type VideoActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'VideoActivity';
    brand?: Maybe<Scalars['String']['output']>;
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']['output']>;
    coachLastName?: Maybe<Scalars['String']['output']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<Video>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type WearableActivity = Activity &
  Node &
  TimeBasedActivity & {
    __typename?: 'WearableActivity';
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    distance?: Maybe<Distance>;
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heartRate?: Maybe<HeartRate>;
    id: Scalars['ID']['output'];
    overlappingActivity?: Maybe<Activity>;
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<Scalars['JSONObject']['output']>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type WearableSample = Node & {
  __typename?: 'WearableSample';
  id: Scalars['ID']['output'];
  platform: Os;
  platformVersion: Scalars['String']['output'];
  sample: Scalars['JSONObject']['output'];
  sdk: WearableSdk;
  startDate: Scalars['ZonedDateTime']['output'];
};

export type WearableSampleEdge = Edge & {
  __typename?: 'WearableSampleEdge';
  cursor: Scalars['String']['output'];
  node: WearableSample;
};

export type WearableSamplesConnection = Connection & {
  __typename?: 'WearableSamplesConnection';
  count: Scalars['Int']['output'];
  edges: Array<WearableSampleEdge>;
  nodes: Array<WearableSample>;
  pageInfo: PageInfo;
};

export type WearableSamplesFilter = {
  platform?: InputMaybe<Os>;
  startDateRange?: InputMaybe<DateRange>;
};

export type WearableSamplesInput = {
  build: Scalars['String']['input'];
  platform: Os;
  platformVersion: Scalars['String']['input'];
  samples: Array<Scalars['JSONObject']['input']>;
  sdk: WearableSdk;
};

export enum WearableSdk {
  GoogleFit = 'GoogleFit',
  HealthConnect = 'HealthConnect',
  HealthKit = 'HealthKit',
}

export enum WelcomeSectionDisplay {
  Default = 'default',
  Hidden = 'hidden',
  ShowAlways = 'showAlways',
}

export enum WorkingStyle {
  Hybrid = 'hybrid',
  Office = 'office',
  Remote = 'remote',
}

export type ZonedDateTimeRange = {
  __typename?: 'ZonedDateTimeRange';
  end?: Maybe<Scalars['ZonedDateTime']['output']>;
  start?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type JoinLiveClassMutationVariables = Exact<{
  classId: Scalars['String']['input'];
}>;

export type JoinLiveClassMutation = {
  __typename?: 'Mutation';
  joinLiveClass: {
    __typename?: 'ActivityPayload';
    activity:
      | { __typename?: 'ArticleActivity'; id: string }
      | { __typename?: 'AudioActivity'; id: string }
      | { __typename?: 'MboClassActivity'; id: string }
      | { __typename?: 'PhysicalActivity'; id: string }
      | { __typename?: 'SleepActivity'; id: string }
      | { __typename?: 'VideoActivity'; id: string }
      | { __typename?: 'WearableActivity'; id: string };
  };
};

export type GetCoachSessionsQueryVariables = Exact<{
  input?: InputMaybe<ConnectionInput>;
  dateFilter?: InputMaybe<Array<DateFilter> | DateFilter>;
  filter?: InputMaybe<CoachSessionFilter>;
}>;

export type GetCoachSessionsQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    sessions: {
      __typename?: 'UserCoachSessionsConnection';
      nodes: Array<{
        __typename?: 'CoachSession';
        id: string;
        sessionStyle: CoachSessionStyle;
        sessionType?: CoachSessionType | null;
        meetingUrl?: string | null;
        joinableTime: string;
        coach: {
          __typename?: 'User';
          firstName?: string | null;
          lastName?: string | null;
          isCoach: boolean;
          id: string;
          email: string;
          isTestUser: boolean;
          registered: boolean;
          fitClient?: { __typename?: 'Client'; id: string } | null;
          avatar?: {
            __typename?: 'Photo';
            basePath: string;
            id: string;
            preview?: string | null;
          } | null;
        };
        calendar?: {
          __typename?: 'Calendar';
          id: string;
          start: string;
          end: string;
          duration: number;
        } | null;
        location?: {
          __typename?: 'CoachLocation';
          id: string;
          name?: string | null;
          studio?: { __typename?: 'Studio'; timezone: string } | null;
        } | null;
      }>;
    };
  } | null;
};

export type CoachSessionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CoachSessionQuery = {
  __typename?: 'Query';
  coachSession?: {
    __typename?: 'CoachSession';
    id: string;
    sessionStyle: CoachSessionStyle;
    sessionType?: CoachSessionType | null;
    meetingUrl?: string | null;
    joinableTime: string;
    coach: {
      __typename?: 'User';
      firstName?: string | null;
      lastName?: string | null;
      isCoach: boolean;
      id: string;
      email: string;
      isTestUser: boolean;
      registered: boolean;
      fitClient?: { __typename?: 'Client'; id: string } | null;
      avatar?: {
        __typename?: 'Photo';
        basePath: string;
        id: string;
        preview?: string | null;
      } | null;
    };
    calendar?: {
      __typename?: 'Calendar';
      id: string;
      start: string;
      end: string;
      duration: number;
    } | null;
    location?: {
      __typename?: 'CoachLocation';
      id: string;
      name?: string | null;
      studio?: { __typename?: 'Studio'; timezone: string } | null;
    } | null;
  } | null;
};

export type CoachSessionNodeFragment = {
  __typename?: 'CoachSession';
  id: string;
  sessionStyle: CoachSessionStyle;
  sessionType?: CoachSessionType | null;
  meetingUrl?: string | null;
  joinableTime: string;
  coach: {
    __typename?: 'User';
    firstName?: string | null;
    lastName?: string | null;
    isCoach: boolean;
    id: string;
    email: string;
    isTestUser: boolean;
    registered: boolean;
    fitClient?: { __typename?: 'Client'; id: string } | null;
    avatar?: {
      __typename?: 'Photo';
      basePath: string;
      id: string;
      preview?: string | null;
    } | null;
  };
  calendar?: {
    __typename?: 'Calendar';
    id: string;
    start: string;
    end: string;
    duration: number;
  } | null;
  location?: {
    __typename?: 'CoachLocation';
    id: string;
    name?: string | null;
    studio?: { __typename?: 'Studio'; timezone: string } | null;
  } | null;
};

export type CoachSessionDetailsFragment = {
  __typename?: 'CoachSession';
  id: string;
  sessionStyle: CoachSessionStyle;
  sessionType?: CoachSessionType | null;
  meetingUrl?: string | null;
  joinableTime: string;
  calendar?: {
    __typename?: 'Calendar';
    id: string;
    start: string;
    end: string;
    duration: number;
  } | null;
  location?: {
    __typename?: 'CoachLocation';
    id: string;
    name?: string | null;
    studio?: { __typename?: 'Studio'; timezone: string } | null;
  } | null;
};

export type CoachUserFragment = {
  __typename?: 'User';
  firstName?: string | null;
  lastName?: string | null;
  isCoach: boolean;
  id: string;
  email: string;
  isTestUser: boolean;
  registered: boolean;
  fitClient?: { __typename?: 'Client'; id: string } | null;
  avatar?: {
    __typename?: 'Photo';
    basePath: string;
    id: string;
    preview?: string | null;
  } | null;
};

export type GetOnDemandSectionsQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetOnDemandSectionsQuery = {
  __typename?: 'Query';
  contentCollection?: {
    __typename?: 'ContentCollection';
    title: string;
    subtitle?: string | null;
    sections: {
      __typename?: 'ContentCollectionSectionsConnection';
      nodes: Array<{
        __typename?: 'ContentSection';
        brand?: string | null;
        id: string;
        title?: string | null;
        heroPhoto?: {
          __typename?: 'Photo';
          basePath: string;
          id: string;
          preview?: string | null;
        } | null;
        bannerPhoto?: {
          __typename?: 'Photo';
          basePath: string;
          id: string;
          preview?: string | null;
        } | null;
        logoPhoto?: {
          __typename?: 'Photo';
          basePath: string;
          id: string;
          preview?: string | null;
        } | null;
      }>;
    };
  } | null;
};

export type OnDemandSectionArticleFragment = {
  __typename?: 'Article';
  id: string;
  title?: string | null;
  heroPhoto?: {
    __typename?: 'Photo';
    basePath: string;
    id: string;
    preview?: string | null;
  } | null;
};

export type OnDemandSectionAudioFragment = {
  __typename?: 'Audio';
  id: string;
  title?: string | null;
  heroPhoto?: {
    __typename?: 'Photo';
    basePath: string;
    id: string;
    preview?: string | null;
  } | null;
};

export type OnDemandSectionClickThroughMediaFragment = {
  __typename?: 'ClickThroughMedia';
  id: string;
  title?: string | null;
  heroPhoto?: {
    __typename?: 'Photo';
    basePath: string;
    id: string;
    preview?: string | null;
  } | null;
};

export type OnDemandSectionContentSectionFragment = {
  __typename?: 'ContentSection';
  id: string;
  title?: string | null;
  heroPhoto?: {
    __typename?: 'Photo';
    basePath: string;
    id: string;
    preview?: string | null;
  } | null;
};

export type OnDemandSectionInAppSurveyFragment = {
  __typename?: 'InAppSurvey';
  id: string;
  title?: string | null;
  heroPhoto?: {
    __typename?: 'Photo';
    basePath: string;
    id: string;
    preview?: string | null;
  } | null;
};

export type OnDemandSectionLinkFragment = {
  __typename?: 'Link';
  id: string;
  title?: string | null;
  heroPhoto?: {
    __typename?: 'Photo';
    basePath: string;
    id: string;
    preview?: string | null;
  } | null;
};

export type OnDemandSectionPracticeProgramFragment = {
  __typename?: 'PracticeProgram';
  id: string;
  title?: string | null;
  heroPhoto?: {
    __typename?: 'Photo';
    basePath: string;
    id: string;
    preview?: string | null;
  } | null;
};

export type OnDemandSectionProgramFragment = {
  __typename?: 'Program';
  id: string;
  title?: string | null;
  heroPhoto?: {
    __typename?: 'Photo';
    basePath: string;
    id: string;
    preview?: string | null;
  } | null;
};

export type OnDemandSectionVideoFragment = {
  __typename?: 'Video';
  id: string;
  title?: string | null;
  heroPhoto?: {
    __typename?: 'Photo';
    basePath: string;
    id: string;
    preview?: string | null;
  } | null;
};

export type OnDemandSectionFragment =
  | OnDemandSectionArticleFragment
  | OnDemandSectionAudioFragment
  | OnDemandSectionClickThroughMediaFragment
  | OnDemandSectionContentSectionFragment
  | OnDemandSectionInAppSurveyFragment
  | OnDemandSectionLinkFragment
  | OnDemandSectionPracticeProgramFragment
  | OnDemandSectionProgramFragment
  | OnDemandSectionVideoFragment;

export type OnDemandVideoPreviewFragment = {
  __typename?: 'Video';
  id: string;
  title?: string | null;
  time?: string | null;
  shortDescription?: string | null;
  author?: string | null;
  brand?: string | null;
  video: string;
  saved?: boolean | null;
  heroPhoto?: {
    __typename?: 'Photo';
    basePath: string;
    id: string;
    preview?: string | null;
  } | null;
  contentEquipment: Array<{
    __typename?: 'Equipment';
    name: string;
    value: string;
  }>;
  contentGenre?: { __typename?: 'Genre'; name: string } | null;
  contentLevel?: { __typename?: 'Level'; name: string; value: string } | null;
};

export type ContentEquipmentBasicsFragment = {
  __typename?: 'Equipment';
  name: string;
  value: string;
};

export type ContentIntensitiesBasicsFragment = {
  __typename?: 'ContentAttribute';
  name: string;
  value: string;
};

export type ContentSplitBasicsFragment = {
  __typename?: 'Split';
  name: string;
  value: string;
};

export type ContentLevelBasicsFragment = {
  __typename?: 'Level';
  name: string;
  value: string;
};

export type ContentGenreBasicsFragment = {
  __typename?: 'Genre';
  id: string;
  name: string;
  value: string;
};

export type ContentGenreWithChildrenFragment = {
  __typename?: 'Genre';
  id: string;
  name: string;
  value: string;
  children: Array<{
    __typename?: 'Genre';
    id: string;
    name: string;
    value: string;
    parent?: {
      __typename?: 'Genre';
      id: string;
      name: string;
      value: string;
    } | null;
  }>;
};

export type GetOnDemandVideoDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOnDemandVideoDetailsQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article' }
    | { __typename?: 'Audio' }
    | { __typename?: 'ClickThroughMedia' }
    | { __typename?: 'ContentSection' }
    | { __typename?: 'InAppSurvey' }
    | { __typename?: 'Link' }
    | { __typename?: 'PracticeProgram' }
    | { __typename?: 'Program' }
    | {
        __typename?: 'Video';
        author?: string | null;
        html?: string | null;
        video: string;
        id: string;
        title?: string | null;
        time?: string | null;
        shortDescription?: string | null;
        brand?: string | null;
        saved?: boolean | null;
        contentEquipment: Array<{
          __typename?: 'Equipment';
          name: string;
          value: string;
        }>;
        heroPhoto?: {
          __typename?: 'Photo';
          basePath: string;
          id: string;
          preview?: string | null;
        } | null;
        contentGenre?: { __typename?: 'Genre'; name: string } | null;
        contentLevel?: {
          __typename?: 'Level';
          name: string;
          value: string;
        } | null;
      }
    | null;
};

export type OnDemandVideoDetailsFragment = {
  __typename?: 'Video';
  author?: string | null;
  html?: string | null;
  video: string;
  id: string;
  title?: string | null;
  time?: string | null;
  shortDescription?: string | null;
  brand?: string | null;
  saved?: boolean | null;
  contentEquipment: Array<{
    __typename?: 'Equipment';
    name: string;
    value: string;
  }>;
  heroPhoto?: {
    __typename?: 'Photo';
    basePath: string;
    id: string;
    preview?: string | null;
  } | null;
  contentGenre?: { __typename?: 'Genre'; name: string } | null;
  contentLevel?: { __typename?: 'Level'; name: string; value: string } | null;
};

export type FilterContentsQueryVariables = Exact<{
  filter?: InputMaybe<ContentFilter>;
  input?: InputMaybe<ConnectionInput>;
}>;

export type FilterContentsQuery = {
  __typename?: 'Query';
  contents: {
    __typename?: 'ContentConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
    };
    nodes: Array<
      | { __typename?: 'Article' }
      | { __typename?: 'Audio' }
      | { __typename?: 'ClickThroughMedia' }
      | { __typename?: 'ContentSection' }
      | { __typename?: 'InAppSurvey' }
      | { __typename?: 'Link' }
      | { __typename?: 'PracticeProgram' }
      | { __typename?: 'Program' }
      | {
          __typename?: 'Video';
          id: string;
          title?: string | null;
          time?: string | null;
          shortDescription?: string | null;
          author?: string | null;
          brand?: string | null;
          video: string;
          saved?: boolean | null;
          heroPhoto?: {
            __typename?: 'Photo';
            basePath: string;
            id: string;
            preview?: string | null;
          } | null;
          contentEquipment: Array<{
            __typename?: 'Equipment';
            name: string;
            value: string;
          }>;
          contentGenre?: { __typename?: 'Genre'; name: string } | null;
          contentLevel?: {
            __typename?: 'Level';
            name: string;
            value: string;
          } | null;
        }
    >;
  };
};

export type GetContentGenresQueryVariables = Exact<{ [key: string]: never }>;

export type GetContentGenresQuery = {
  __typename?: 'Query';
  contentGenres: Array<{
    __typename?: 'Genre';
    id: string;
    name: string;
    value: string;
    children: Array<{
      __typename?: 'Genre';
      id: string;
      name: string;
      value: string;
      parent?: {
        __typename?: 'Genre';
        id: string;
        name: string;
        value: string;
      } | null;
    }>;
  }>;
};

export type GetContentAttributesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetContentAttributesQuery = {
  __typename?: 'Query';
  contentGenres: Array<{
    __typename?: 'Genre';
    id: string;
    name: string;
    value: string;
    children: Array<{
      __typename?: 'Genre';
      id: string;
      name: string;
      value: string;
      parent?: {
        __typename?: 'Genre';
        id: string;
        name: string;
        value: string;
      } | null;
    }>;
  }>;
  contentEquipment: Array<{
    __typename?: 'Equipment';
    name: string;
    value: string;
  }>;
  contentLevels: Array<{ __typename?: 'Level'; name: string; value: string }>;
  contentSplits: Array<{ __typename?: 'Split'; name: string; value: string }>;
  contentIntensities: Array<{
    __typename?: 'ContentAttribute';
    name: string;
    value: string;
  }>;
};

export type UpdateProgressMutationVariables = Exact<{
  sectionId?: InputMaybe<Scalars['ID']['input']>;
  contentId: Scalars['ID']['input'];
  progress: Scalars['Float']['input'];
}>;

export type UpdateProgressMutation = {
  __typename?: 'Mutation';
  updateProgress: {
    __typename?: 'ContentEdge';
    cursor: string;
    completed: boolean;
    progress?: number | null;
  };
};

export type AddSavedMutationVariables = Exact<{
  contentId: Scalars['ID']['input'];
}>;

export type AddSavedMutation = {
  __typename?: 'Mutation';
  addSaved: {
    __typename?: 'SavedPayload';
    saved: boolean;
    content:
      | { __typename?: 'Article' }
      | { __typename?: 'Audio' }
      | { __typename?: 'ClickThroughMedia' }
      | { __typename?: 'ContentSection' }
      | { __typename?: 'InAppSurvey' }
      | { __typename?: 'Link' }
      | { __typename?: 'PracticeProgram' }
      | { __typename?: 'Program' }
      | {
          __typename?: 'Video';
          id: string;
          title?: string | null;
          time?: string | null;
          shortDescription?: string | null;
          author?: string | null;
          brand?: string | null;
          video: string;
          saved?: boolean | null;
          heroPhoto?: {
            __typename?: 'Photo';
            basePath: string;
            id: string;
            preview?: string | null;
          } | null;
          contentEquipment: Array<{
            __typename?: 'Equipment';
            name: string;
            value: string;
          }>;
          contentGenre?: { __typename?: 'Genre'; name: string } | null;
          contentLevel?: {
            __typename?: 'Level';
            name: string;
            value: string;
          } | null;
        };
  };
};

export type RemoveSavedMutationVariables = Exact<{
  contentId: Scalars['ID']['input'];
}>;

export type RemoveSavedMutation = {
  __typename?: 'Mutation';
  removeSaved: {
    __typename?: 'SavedPayload';
    saved: boolean;
    content:
      | { __typename?: 'Article' }
      | { __typename?: 'Audio' }
      | { __typename?: 'ClickThroughMedia' }
      | { __typename?: 'ContentSection' }
      | { __typename?: 'InAppSurvey' }
      | { __typename?: 'Link' }
      | { __typename?: 'PracticeProgram' }
      | { __typename?: 'Program' }
      | {
          __typename?: 'Video';
          id: string;
          title?: string | null;
          time?: string | null;
          shortDescription?: string | null;
          author?: string | null;
          brand?: string | null;
          video: string;
          saved?: boolean | null;
          heroPhoto?: {
            __typename?: 'Photo';
            basePath: string;
            id: string;
            preview?: string | null;
          } | null;
          contentEquipment: Array<{
            __typename?: 'Equipment';
            name: string;
            value: string;
          }>;
          contentGenre?: { __typename?: 'Genre'; name: string } | null;
          contentLevel?: {
            __typename?: 'Level';
            name: string;
            value: string;
          } | null;
        };
  };
};

export type GetOnDemandVideoBasicsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOnDemandVideoBasicsQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article' }
    | { __typename?: 'Audio' }
    | { __typename?: 'ClickThroughMedia' }
    | { __typename?: 'ContentSection' }
    | { __typename?: 'InAppSurvey' }
    | { __typename?: 'Link' }
    | { __typename?: 'PracticeProgram' }
    | { __typename?: 'Program' }
    | {
        __typename?: 'Video';
        id: string;
        title?: string | null;
        time?: string | null;
        shortDescription?: string | null;
        author?: string | null;
        brand?: string | null;
        video: string;
        saved?: boolean | null;
        heroPhoto?: {
          __typename?: 'Photo';
          basePath: string;
          id: string;
          preview?: string | null;
        } | null;
        contentEquipment: Array<{
          __typename?: 'Equipment';
          name: string;
          value: string;
        }>;
        contentGenre?: { __typename?: 'Genre'; name: string } | null;
        contentLevel?: {
          __typename?: 'Level';
          name: string;
          value: string;
        } | null;
      }
    | null;
};

export type MboClassDetailsFragment = {
  __typename?: 'MboClass';
  id: string;
  name: string;
  subtype: MboClassSubtype;
  category: string;
  attending: boolean;
  mboClassId: number;
  cancelled: boolean;
  full: boolean;
  description: string;
  staffFirstName?: string | null;
  staffLastName?: string | null;
  room?: string | null;
  joinUrl?: string | null;
  joinableTime: string;
  endTime: string;
  startTime: string;
  staff?: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    isCoach: boolean;
    isTestUser: boolean;
    registered: boolean;
    fitClient?: { __typename?: 'Client'; id: string } | null;
    avatar?: {
      __typename?: 'Photo';
      basePath: string;
      id: string;
      preview?: string | null;
    } | null;
  } | null;
  studio: { __typename?: 'Studio'; id: string; mboStudioId: string };
  location?: {
    __typename?: 'CoachLocation';
    id: string;
    name?: string | null;
  } | null;
};

export type MboClassQueryVariables = Exact<{
  input: MboClassDetailsInput;
}>;

export type MboClassQuery = {
  __typename?: 'Query';
  mboClass?: {
    __typename?: 'MboClass';
    id: string;
    name: string;
    subtype: MboClassSubtype;
    category: string;
    attending: boolean;
    mboClassId: number;
    cancelled: boolean;
    full: boolean;
    description: string;
    staffFirstName?: string | null;
    staffLastName?: string | null;
    room?: string | null;
    joinUrl?: string | null;
    joinableTime: string;
    endTime: string;
    startTime: string;
    staff?: {
      __typename?: 'User';
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      isCoach: boolean;
      isTestUser: boolean;
      registered: boolean;
      fitClient?: { __typename?: 'Client'; id: string } | null;
      avatar?: {
        __typename?: 'Photo';
        basePath: string;
        id: string;
        preview?: string | null;
      } | null;
    } | null;
    studio: { __typename?: 'Studio'; id: string; mboStudioId: string };
    location?: {
      __typename?: 'CoachLocation';
      id: string;
      name?: string | null;
    } | null;
  } | null;
};

export type GetBookedClassesAndSessionsByDateRangeQueryVariables = Exact<{
  filter: MboBookingsFilter;
  sessionDateFilter?: InputMaybe<Array<DateFilter> | DateFilter>;
}>;

export type GetBookedClassesAndSessionsByDateRangeQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    bookedClasses: {
      __typename?: 'UserBookingsConnection';
      count: number;
      edges: Array<{
        __typename?: 'UserBookingsEdge';
        cursor: string;
        node: {
          __typename?: 'MboClass';
          id: string;
          name: string;
          subtype: MboClassSubtype;
          category: string;
          attending: boolean;
          mboClassId: number;
          cancelled: boolean;
          full: boolean;
          description: string;
          staffFirstName?: string | null;
          staffLastName?: string | null;
          room?: string | null;
          joinUrl?: string | null;
          joinableTime: string;
          endTime: string;
          startTime: string;
          staff?: {
            __typename?: 'User';
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            isCoach: boolean;
            isTestUser: boolean;
            registered: boolean;
            fitClient?: { __typename?: 'Client'; id: string } | null;
            avatar?: {
              __typename?: 'Photo';
              basePath: string;
              id: string;
              preview?: string | null;
            } | null;
          } | null;
          studio: { __typename?: 'Studio'; id: string; mboStudioId: string };
          location?: {
            __typename?: 'CoachLocation';
            id: string;
            name?: string | null;
          } | null;
        };
      }>;
    };
    sessions: {
      __typename?: 'UserCoachSessionsConnection';
      count: number;
      edges: Array<{
        __typename?: 'UserCoachSessionsEdge';
        cursor: string;
        node: {
          __typename?: 'CoachSession';
          id: string;
          sessionStyle: CoachSessionStyle;
          sessionType?: CoachSessionType | null;
          meetingUrl?: string | null;
          joinableTime: string;
          coach: {
            __typename?: 'User';
            firstName?: string | null;
            lastName?: string | null;
            isCoach: boolean;
            id: string;
            email: string;
            isTestUser: boolean;
            registered: boolean;
            fitClient?: { __typename?: 'Client'; id: string } | null;
            avatar?: {
              __typename?: 'Photo';
              basePath: string;
              id: string;
              preview?: string | null;
            } | null;
          };
          calendar?: {
            __typename?: 'Calendar';
            id: string;
            start: string;
            end: string;
            duration: number;
          } | null;
          location?: {
            __typename?: 'CoachLocation';
            id: string;
            name?: string | null;
            studio?: { __typename?: 'Studio'; timezone: string } | null;
          } | null;
        };
      }>;
    };
  } | null;
};

export type PhotoBasicsFragment = {
  __typename?: 'Photo';
  basePath: string;
  id: string;
  preview?: string | null;
};

export type SelfQueryVariables = Exact<{ [key: string]: never }>;

export type SelfQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    firstName?: string | null;
    lastName?: string | null;
    id: string;
    email: string;
    isTestUser: boolean;
    registered: boolean;
    fitClient?: { __typename?: 'Client'; id: string } | null;
    avatar?: {
      __typename?: 'Photo';
      basePath: string;
      id: string;
      preview?: string | null;
    } | null;
  } | null;
};

export type UserBasicsFragment = {
  __typename?: 'User';
  id: string;
  email: string;
  isTestUser: boolean;
  registered: boolean;
  fitClient?: { __typename?: 'Client'; id: string } | null;
  avatar?: {
    __typename?: 'Photo';
    basePath: string;
    id: string;
    preview?: string | null;
  } | null;
};

export type IterableInitQueryVariables = Exact<{ [key: string]: never }>;

export type IterableInitQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    email: string;
    iterableToken: { __typename?: 'Token'; accessToken: string };
  } | null;
};

export type AuthHookQueryVariables = Exact<{ [key: string]: never }>;

export type AuthHookQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    email: string;
    registered: boolean;
  } | null;
};

export type AuthenticatedLayoutQueryVariables = Exact<{ [key: string]: never }>;

export type AuthenticatedLayoutQuery = {
  __typename?: 'Query';
  self?: { __typename?: 'User'; id: string; email: string } | null;
};

export type UserDetailsFragment = {
  __typename?: 'User';
  firstName?: string | null;
  lastName?: string | null;
  id: string;
  email: string;
  isTestUser: boolean;
  registered: boolean;
  fitClient?: { __typename?: 'Client'; id: string } | null;
  avatar?: {
    __typename?: 'Photo';
    basePath: string;
    id: string;
    preview?: string | null;
  } | null;
};

export type MixpanelHookQueryVariables = Exact<{ [key: string]: never }>;

export type MixpanelHookQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    email: string;
    fitCoach?: { __typename?: 'User'; id: string } | null;
  } | null;
};

export type UpdateLastActivityMutationVariables = Exact<{
  [key: string]: never;
}>;

export type UpdateLastActivityMutation = {
  __typename?: 'Mutation';
  updateLastActivity?: {
    __typename?: 'UpdateUserPayload';
    user: { __typename?: 'User'; id: string };
  } | null;
};

export type GetUserSavedVideosQueryVariables = Exact<{
  connectionInput?: InputMaybe<ConnectionInput>;
}>;

export type GetUserSavedVideosQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    savedContent: {
      __typename?: 'ContentSavedConnection';
      count: number;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
      nodes: Array<
        | { __typename?: 'Article' }
        | { __typename?: 'Audio' }
        | { __typename?: 'ClickThroughMedia' }
        | { __typename?: 'ContentSection' }
        | { __typename?: 'InAppSurvey' }
        | { __typename?: 'Link' }
        | { __typename?: 'PracticeProgram' }
        | { __typename?: 'Program' }
        | {
            __typename?: 'Video';
            id: string;
            title?: string | null;
            time?: string | null;
            shortDescription?: string | null;
            author?: string | null;
            brand?: string | null;
            video: string;
            saved?: boolean | null;
            heroPhoto?: {
              __typename?: 'Photo';
              basePath: string;
              id: string;
              preview?: string | null;
            } | null;
            contentEquipment: Array<{
              __typename?: 'Equipment';
              name: string;
              value: string;
            }>;
            contentGenre?: { __typename?: 'Genre'; name: string } | null;
            contentLevel?: {
              __typename?: 'Level';
              name: string;
              value: string;
            } | null;
          }
      >;
    };
  } | null;
};

export const CoachSessionDetailsFragmentDoc = gql`
  fragment CoachSessionDetails on CoachSession {
    id
    sessionStyle
    sessionType
    meetingUrl
    joinableTime
    calendar {
      id
      start
      end
      duration
    }
    location {
      id
      name
      studio {
        timezone
      }
    }
  }
`;
export const PhotoBasicsFragmentDoc = gql`
  fragment PhotoBasics on Photo {
    basePath
    id
    preview
  }
`;
export const UserBasicsFragmentDoc = gql`
  fragment UserBasics on User {
    id
    email
    isTestUser
    registered
    fitClient {
      id
    }
    avatar {
      ...PhotoBasics
    }
  }
  ${PhotoBasicsFragmentDoc}
`;
export const CoachUserFragmentDoc = gql`
  fragment CoachUser on User {
    ...UserBasics
    firstName
    lastName
    isCoach
  }
  ${UserBasicsFragmentDoc}
`;
export const CoachSessionNodeFragmentDoc = gql`
  fragment CoachSessionNode on CoachSession {
    ...CoachSessionDetails
    coach {
      ...CoachUser
    }
  }
  ${CoachSessionDetailsFragmentDoc}
  ${CoachUserFragmentDoc}
`;
export const OnDemandSectionFragmentDoc = gql`
  fragment OnDemandSection on Content {
    id
    title
    heroPhoto {
      ...PhotoBasics
    }
  }
  ${PhotoBasicsFragmentDoc}
`;
export const ContentIntensitiesBasicsFragmentDoc = gql`
  fragment ContentIntensitiesBasics on ContentAttribute {
    name
    value
  }
`;
export const ContentSplitBasicsFragmentDoc = gql`
  fragment ContentSplitBasics on Split {
    name
    value
  }
`;
export const ContentLevelBasicsFragmentDoc = gql`
  fragment ContentLevelBasics on Level {
    name
    value
  }
`;
export const ContentGenreBasicsFragmentDoc = gql`
  fragment ContentGenreBasics on Genre {
    id
    name
    value
  }
`;
export const ContentGenreWithChildrenFragmentDoc = gql`
  fragment ContentGenreWithChildren on Genre {
    ...ContentGenreBasics
    children {
      ...ContentGenreBasics
      parent {
        ...ContentGenreBasics
      }
    }
  }
  ${ContentGenreBasicsFragmentDoc}
`;
export const OnDemandVideoPreviewFragmentDoc = gql`
  fragment OnDemandVideoPreview on Video {
    id
    title
    time
    heroPhoto {
      ...PhotoBasics
    }
    contentEquipment {
      name
      value
    }
    shortDescription
    author
    brand
    contentGenre {
      name
    }
    contentLevel {
      name
      value
    }
    video
    saved
  }
  ${PhotoBasicsFragmentDoc}
`;
export const ContentEquipmentBasicsFragmentDoc = gql`
  fragment ContentEquipmentBasics on Equipment {
    name
    value
  }
`;
export const OnDemandVideoDetailsFragmentDoc = gql`
  fragment OnDemandVideoDetails on Video {
    ...OnDemandVideoPreview
    author
    html
    video
    contentEquipment {
      ...ContentEquipmentBasics
    }
  }
  ${OnDemandVideoPreviewFragmentDoc}
  ${ContentEquipmentBasicsFragmentDoc}
`;
export const MboClassDetailsFragmentDoc = gql`
  fragment MboClassDetails on MboClass {
    id
    name
    subtype
    category
    attending
    mboClassId
    cancelled
    full
    description
    staffFirstName
    staffLastName
    staff {
      id
      email
      ...CoachUser
    }
    room
    studio {
      id
      mboStudioId
    }
    joinUrl
    joinableTime
    location {
      id
      name
    }
    endTime
    startTime
  }
  ${CoachUserFragmentDoc}
`;
export const UserDetailsFragmentDoc = gql`
  fragment UserDetails on User {
    ...UserBasics
    firstName
    lastName
  }
  ${UserBasicsFragmentDoc}
`;
export const JoinLiveClassDocument = gql`
  mutation joinLiveClass($classId: String!) {
    joinLiveClass(classId: $classId) {
      activity {
        id
      }
    }
  }
`;
export type JoinLiveClassMutationFn = Apollo.MutationFunction<
  JoinLiveClassMutation,
  JoinLiveClassMutationVariables
>;

/**
 * __useJoinLiveClassMutation__
 *
 * To run a mutation, you first call `useJoinLiveClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinLiveClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinLiveClassMutation, { data, loading, error }] = useJoinLiveClassMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useJoinLiveClassMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinLiveClassMutation,
    JoinLiveClassMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinLiveClassMutation,
    JoinLiveClassMutationVariables
  >(JoinLiveClassDocument, options);
}
export type JoinLiveClassMutationHookResult = ReturnType<
  typeof useJoinLiveClassMutation
>;
export type JoinLiveClassMutationResult =
  Apollo.MutationResult<JoinLiveClassMutation>;
export type JoinLiveClassMutationOptions = Apollo.BaseMutationOptions<
  JoinLiveClassMutation,
  JoinLiveClassMutationVariables
>;
export const GetCoachSessionsDocument = gql`
  query getCoachSessions(
    $input: ConnectionInput
    $dateFilter: [DateFilter!]
    $filter: CoachSessionFilter
  ) {
    self {
      id
      sessions(input: $input, dateFilter: $dateFilter, filter: $filter) {
        nodes {
          ...CoachSessionNode
        }
      }
    }
  }
  ${CoachSessionNodeFragmentDoc}
`;

/**
 * __useGetCoachSessionsQuery__
 *
 * To run a query within a React component, call `useGetCoachSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachSessionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      dateFilter: // value for 'dateFilter'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCoachSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCoachSessionsQuery,
    GetCoachSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoachSessionsQuery, GetCoachSessionsQueryVariables>(
    GetCoachSessionsDocument,
    options,
  );
}
export function useGetCoachSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoachSessionsQuery,
    GetCoachSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCoachSessionsQuery,
    GetCoachSessionsQueryVariables
  >(GetCoachSessionsDocument, options);
}
export function useGetCoachSessionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCoachSessionsQuery,
        GetCoachSessionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCoachSessionsQuery,
    GetCoachSessionsQueryVariables
  >(GetCoachSessionsDocument, options);
}
export type GetCoachSessionsQueryHookResult = ReturnType<
  typeof useGetCoachSessionsQuery
>;
export type GetCoachSessionsLazyQueryHookResult = ReturnType<
  typeof useGetCoachSessionsLazyQuery
>;
export type GetCoachSessionsSuspenseQueryHookResult = ReturnType<
  typeof useGetCoachSessionsSuspenseQuery
>;
export type GetCoachSessionsQueryResult = Apollo.QueryResult<
  GetCoachSessionsQuery,
  GetCoachSessionsQueryVariables
>;
export const CoachSessionDocument = gql`
  query coachSession($id: ID!) {
    coachSession(id: $id) {
      ...CoachSessionNode
    }
  }
  ${CoachSessionNodeFragmentDoc}
`;

/**
 * __useCoachSessionQuery__
 *
 * To run a query within a React component, call `useCoachSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCoachSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoachSessionQuery,
    CoachSessionQueryVariables
  > &
    (
      | { variables: CoachSessionQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoachSessionQuery, CoachSessionQueryVariables>(
    CoachSessionDocument,
    options,
  );
}
export function useCoachSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoachSessionQuery,
    CoachSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoachSessionQuery, CoachSessionQueryVariables>(
    CoachSessionDocument,
    options,
  );
}
export function useCoachSessionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CoachSessionQuery,
        CoachSessionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CoachSessionQuery, CoachSessionQueryVariables>(
    CoachSessionDocument,
    options,
  );
}
export type CoachSessionQueryHookResult = ReturnType<
  typeof useCoachSessionQuery
>;
export type CoachSessionLazyQueryHookResult = ReturnType<
  typeof useCoachSessionLazyQuery
>;
export type CoachSessionSuspenseQueryHookResult = ReturnType<
  typeof useCoachSessionSuspenseQuery
>;
export type CoachSessionQueryResult = Apollo.QueryResult<
  CoachSessionQuery,
  CoachSessionQueryVariables
>;
export const GetOnDemandSectionsDocument = gql`
  query getOnDemandSections($slug: String!) {
    contentCollection(slug: $slug) {
      title
      subtitle
      sections {
        nodes {
          brand
          heroPhoto {
            ...PhotoBasics
          }
          bannerPhoto {
            ...PhotoBasics
          }
          logoPhoto {
            ...PhotoBasics
          }
          ...OnDemandSection
        }
      }
    }
  }
  ${PhotoBasicsFragmentDoc}
  ${OnDemandSectionFragmentDoc}
`;

/**
 * __useGetOnDemandSectionsQuery__
 *
 * To run a query within a React component, call `useGetOnDemandSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnDemandSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnDemandSectionsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetOnDemandSectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOnDemandSectionsQuery,
    GetOnDemandSectionsQueryVariables
  > &
    (
      | { variables: GetOnDemandSectionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOnDemandSectionsQuery,
    GetOnDemandSectionsQueryVariables
  >(GetOnDemandSectionsDocument, options);
}
export function useGetOnDemandSectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnDemandSectionsQuery,
    GetOnDemandSectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOnDemandSectionsQuery,
    GetOnDemandSectionsQueryVariables
  >(GetOnDemandSectionsDocument, options);
}
export function useGetOnDemandSectionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetOnDemandSectionsQuery,
        GetOnDemandSectionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOnDemandSectionsQuery,
    GetOnDemandSectionsQueryVariables
  >(GetOnDemandSectionsDocument, options);
}
export type GetOnDemandSectionsQueryHookResult = ReturnType<
  typeof useGetOnDemandSectionsQuery
>;
export type GetOnDemandSectionsLazyQueryHookResult = ReturnType<
  typeof useGetOnDemandSectionsLazyQuery
>;
export type GetOnDemandSectionsSuspenseQueryHookResult = ReturnType<
  typeof useGetOnDemandSectionsSuspenseQuery
>;
export type GetOnDemandSectionsQueryResult = Apollo.QueryResult<
  GetOnDemandSectionsQuery,
  GetOnDemandSectionsQueryVariables
>;
export const GetOnDemandVideoDetailsDocument = gql`
  query getOnDemandVideoDetails($id: ID!) {
    content(id: $id) {
      ...OnDemandVideoDetails
    }
  }
  ${OnDemandVideoDetailsFragmentDoc}
`;

/**
 * __useGetOnDemandVideoDetailsQuery__
 *
 * To run a query within a React component, call `useGetOnDemandVideoDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnDemandVideoDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnDemandVideoDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOnDemandVideoDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOnDemandVideoDetailsQuery,
    GetOnDemandVideoDetailsQueryVariables
  > &
    (
      | { variables: GetOnDemandVideoDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOnDemandVideoDetailsQuery,
    GetOnDemandVideoDetailsQueryVariables
  >(GetOnDemandVideoDetailsDocument, options);
}
export function useGetOnDemandVideoDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnDemandVideoDetailsQuery,
    GetOnDemandVideoDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOnDemandVideoDetailsQuery,
    GetOnDemandVideoDetailsQueryVariables
  >(GetOnDemandVideoDetailsDocument, options);
}
export function useGetOnDemandVideoDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetOnDemandVideoDetailsQuery,
        GetOnDemandVideoDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOnDemandVideoDetailsQuery,
    GetOnDemandVideoDetailsQueryVariables
  >(GetOnDemandVideoDetailsDocument, options);
}
export type GetOnDemandVideoDetailsQueryHookResult = ReturnType<
  typeof useGetOnDemandVideoDetailsQuery
>;
export type GetOnDemandVideoDetailsLazyQueryHookResult = ReturnType<
  typeof useGetOnDemandVideoDetailsLazyQuery
>;
export type GetOnDemandVideoDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetOnDemandVideoDetailsSuspenseQuery
>;
export type GetOnDemandVideoDetailsQueryResult = Apollo.QueryResult<
  GetOnDemandVideoDetailsQuery,
  GetOnDemandVideoDetailsQueryVariables
>;
export const FilterContentsDocument = gql`
  query filterContents($filter: ContentFilter, $input: ConnectionInput) {
    contents(filter: $filter, input: $input) {
      count
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        ...OnDemandVideoPreview
      }
    }
  }
  ${OnDemandVideoPreviewFragmentDoc}
`;

/**
 * __useFilterContentsQuery__
 *
 * To run a query within a React component, call `useFilterContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterContentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFilterContentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterContentsQuery,
    FilterContentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterContentsQuery, FilterContentsQueryVariables>(
    FilterContentsDocument,
    options,
  );
}
export function useFilterContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterContentsQuery,
    FilterContentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterContentsQuery, FilterContentsQueryVariables>(
    FilterContentsDocument,
    options,
  );
}
export function useFilterContentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FilterContentsQuery,
        FilterContentsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FilterContentsQuery,
    FilterContentsQueryVariables
  >(FilterContentsDocument, options);
}
export type FilterContentsQueryHookResult = ReturnType<
  typeof useFilterContentsQuery
>;
export type FilterContentsLazyQueryHookResult = ReturnType<
  typeof useFilterContentsLazyQuery
>;
export type FilterContentsSuspenseQueryHookResult = ReturnType<
  typeof useFilterContentsSuspenseQuery
>;
export type FilterContentsQueryResult = Apollo.QueryResult<
  FilterContentsQuery,
  FilterContentsQueryVariables
>;
export const GetContentGenresDocument = gql`
  query getContentGenres {
    contentGenres {
      ...ContentGenreWithChildren
    }
  }
  ${ContentGenreWithChildrenFragmentDoc}
`;

/**
 * __useGetContentGenresQuery__
 *
 * To run a query within a React component, call `useGetContentGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentGenresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContentGenresQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContentGenresQuery,
    GetContentGenresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContentGenresQuery, GetContentGenresQueryVariables>(
    GetContentGenresDocument,
    options,
  );
}
export function useGetContentGenresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContentGenresQuery,
    GetContentGenresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContentGenresQuery,
    GetContentGenresQueryVariables
  >(GetContentGenresDocument, options);
}
export function useGetContentGenresSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContentGenresQuery,
        GetContentGenresQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContentGenresQuery,
    GetContentGenresQueryVariables
  >(GetContentGenresDocument, options);
}
export type GetContentGenresQueryHookResult = ReturnType<
  typeof useGetContentGenresQuery
>;
export type GetContentGenresLazyQueryHookResult = ReturnType<
  typeof useGetContentGenresLazyQuery
>;
export type GetContentGenresSuspenseQueryHookResult = ReturnType<
  typeof useGetContentGenresSuspenseQuery
>;
export type GetContentGenresQueryResult = Apollo.QueryResult<
  GetContentGenresQuery,
  GetContentGenresQueryVariables
>;
export const GetContentAttributesDocument = gql`
  query getContentAttributes {
    contentGenres {
      ...ContentGenreWithChildren
    }
    contentEquipment {
      ...ContentEquipmentBasics
    }
    contentLevels {
      ...ContentLevelBasics
    }
    contentSplits {
      ...ContentSplitBasics
    }
    contentIntensities {
      ...ContentIntensitiesBasics
    }
  }
  ${ContentGenreWithChildrenFragmentDoc}
  ${ContentEquipmentBasicsFragmentDoc}
  ${ContentLevelBasicsFragmentDoc}
  ${ContentSplitBasicsFragmentDoc}
  ${ContentIntensitiesBasicsFragmentDoc}
`;

/**
 * __useGetContentAttributesQuery__
 *
 * To run a query within a React component, call `useGetContentAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContentAttributesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContentAttributesQuery,
    GetContentAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContentAttributesQuery,
    GetContentAttributesQueryVariables
  >(GetContentAttributesDocument, options);
}
export function useGetContentAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContentAttributesQuery,
    GetContentAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContentAttributesQuery,
    GetContentAttributesQueryVariables
  >(GetContentAttributesDocument, options);
}
export function useGetContentAttributesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContentAttributesQuery,
        GetContentAttributesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetContentAttributesQuery,
    GetContentAttributesQueryVariables
  >(GetContentAttributesDocument, options);
}
export type GetContentAttributesQueryHookResult = ReturnType<
  typeof useGetContentAttributesQuery
>;
export type GetContentAttributesLazyQueryHookResult = ReturnType<
  typeof useGetContentAttributesLazyQuery
>;
export type GetContentAttributesSuspenseQueryHookResult = ReturnType<
  typeof useGetContentAttributesSuspenseQuery
>;
export type GetContentAttributesQueryResult = Apollo.QueryResult<
  GetContentAttributesQuery,
  GetContentAttributesQueryVariables
>;
export const UpdateProgressDocument = gql`
  mutation updateProgress($sectionId: ID, $contentId: ID!, $progress: Float!) {
    updateProgress(
      sectionId: $sectionId
      contentId: $contentId
      progress: $progress
    ) {
      cursor
      completed
      progress
    }
  }
`;
export type UpdateProgressMutationFn = Apollo.MutationFunction<
  UpdateProgressMutation,
  UpdateProgressMutationVariables
>;

/**
 * __useUpdateProgressMutation__
 *
 * To run a mutation, you first call `useUpdateProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgressMutation, { data, loading, error }] = useUpdateProgressMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      contentId: // value for 'contentId'
 *      progress: // value for 'progress'
 *   },
 * });
 */
export function useUpdateProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProgressMutation,
    UpdateProgressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProgressMutation,
    UpdateProgressMutationVariables
  >(UpdateProgressDocument, options);
}
export type UpdateProgressMutationHookResult = ReturnType<
  typeof useUpdateProgressMutation
>;
export type UpdateProgressMutationResult =
  Apollo.MutationResult<UpdateProgressMutation>;
export type UpdateProgressMutationOptions = Apollo.BaseMutationOptions<
  UpdateProgressMutation,
  UpdateProgressMutationVariables
>;
export const AddSavedDocument = gql`
  mutation addSaved($contentId: ID!) {
    addSaved(contentId: $contentId) {
      saved
      content {
        ...OnDemandVideoPreview
      }
    }
  }
  ${OnDemandVideoPreviewFragmentDoc}
`;
export type AddSavedMutationFn = Apollo.MutationFunction<
  AddSavedMutation,
  AddSavedMutationVariables
>;

/**
 * __useAddSavedMutation__
 *
 * To run a mutation, you first call `useAddSavedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSavedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSavedMutation, { data, loading, error }] = useAddSavedMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useAddSavedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSavedMutation,
    AddSavedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSavedMutation, AddSavedMutationVariables>(
    AddSavedDocument,
    options,
  );
}
export type AddSavedMutationHookResult = ReturnType<typeof useAddSavedMutation>;
export type AddSavedMutationResult = Apollo.MutationResult<AddSavedMutation>;
export type AddSavedMutationOptions = Apollo.BaseMutationOptions<
  AddSavedMutation,
  AddSavedMutationVariables
>;
export const RemoveSavedDocument = gql`
  mutation removeSaved($contentId: ID!) {
    removeSaved(contentId: $contentId) {
      saved
      content {
        ...OnDemandVideoPreview
      }
    }
  }
  ${OnDemandVideoPreviewFragmentDoc}
`;
export type RemoveSavedMutationFn = Apollo.MutationFunction<
  RemoveSavedMutation,
  RemoveSavedMutationVariables
>;

/**
 * __useRemoveSavedMutation__
 *
 * To run a mutation, you first call `useRemoveSavedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSavedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSavedMutation, { data, loading, error }] = useRemoveSavedMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useRemoveSavedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSavedMutation,
    RemoveSavedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveSavedMutation, RemoveSavedMutationVariables>(
    RemoveSavedDocument,
    options,
  );
}
export type RemoveSavedMutationHookResult = ReturnType<
  typeof useRemoveSavedMutation
>;
export type RemoveSavedMutationResult =
  Apollo.MutationResult<RemoveSavedMutation>;
export type RemoveSavedMutationOptions = Apollo.BaseMutationOptions<
  RemoveSavedMutation,
  RemoveSavedMutationVariables
>;
export const GetOnDemandVideoBasicsDocument = gql`
  query getOnDemandVideoBasics($id: ID!) {
    content(id: $id) {
      ...OnDemandVideoPreview
    }
  }
  ${OnDemandVideoPreviewFragmentDoc}
`;

/**
 * __useGetOnDemandVideoBasicsQuery__
 *
 * To run a query within a React component, call `useGetOnDemandVideoBasicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnDemandVideoBasicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnDemandVideoBasicsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOnDemandVideoBasicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOnDemandVideoBasicsQuery,
    GetOnDemandVideoBasicsQueryVariables
  > &
    (
      | { variables: GetOnDemandVideoBasicsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOnDemandVideoBasicsQuery,
    GetOnDemandVideoBasicsQueryVariables
  >(GetOnDemandVideoBasicsDocument, options);
}
export function useGetOnDemandVideoBasicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnDemandVideoBasicsQuery,
    GetOnDemandVideoBasicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOnDemandVideoBasicsQuery,
    GetOnDemandVideoBasicsQueryVariables
  >(GetOnDemandVideoBasicsDocument, options);
}
export function useGetOnDemandVideoBasicsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetOnDemandVideoBasicsQuery,
        GetOnDemandVideoBasicsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOnDemandVideoBasicsQuery,
    GetOnDemandVideoBasicsQueryVariables
  >(GetOnDemandVideoBasicsDocument, options);
}
export type GetOnDemandVideoBasicsQueryHookResult = ReturnType<
  typeof useGetOnDemandVideoBasicsQuery
>;
export type GetOnDemandVideoBasicsLazyQueryHookResult = ReturnType<
  typeof useGetOnDemandVideoBasicsLazyQuery
>;
export type GetOnDemandVideoBasicsSuspenseQueryHookResult = ReturnType<
  typeof useGetOnDemandVideoBasicsSuspenseQuery
>;
export type GetOnDemandVideoBasicsQueryResult = Apollo.QueryResult<
  GetOnDemandVideoBasicsQuery,
  GetOnDemandVideoBasicsQueryVariables
>;
export const MboClassDocument = gql`
  query mboClass($input: MboClassDetailsInput!) {
    mboClass(input: $input) {
      ...MboClassDetails
    }
  }
  ${MboClassDetailsFragmentDoc}
`;

/**
 * __useMboClassQuery__
 *
 * To run a query within a React component, call `useMboClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useMboClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMboClassQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMboClassQuery(
  baseOptions: Apollo.QueryHookOptions<MboClassQuery, MboClassQueryVariables> &
    ({ variables: MboClassQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MboClassQuery, MboClassQueryVariables>(
    MboClassDocument,
    options,
  );
}
export function useMboClassLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MboClassQuery,
    MboClassQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MboClassQuery, MboClassQueryVariables>(
    MboClassDocument,
    options,
  );
}
export function useMboClassSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MboClassQuery, MboClassQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MboClassQuery, MboClassQueryVariables>(
    MboClassDocument,
    options,
  );
}
export type MboClassQueryHookResult = ReturnType<typeof useMboClassQuery>;
export type MboClassLazyQueryHookResult = ReturnType<
  typeof useMboClassLazyQuery
>;
export type MboClassSuspenseQueryHookResult = ReturnType<
  typeof useMboClassSuspenseQuery
>;
export type MboClassQueryResult = Apollo.QueryResult<
  MboClassQuery,
  MboClassQueryVariables
>;
export const GetBookedClassesAndSessionsByDateRangeDocument = gql`
  query getBookedClassesAndSessionsByDateRange(
    $filter: MboBookingsFilter!
    $sessionDateFilter: [DateFilter!]
  ) {
    self {
      id
      bookedClasses(filter: $filter) {
        count
        edges {
          cursor
          node {
            ...MboClassDetails
          }
        }
      }
      sessions(dateFilter: $sessionDateFilter) {
        count
        edges {
          cursor
          node {
            ...CoachSessionNode
          }
        }
      }
    }
  }
  ${MboClassDetailsFragmentDoc}
  ${CoachSessionNodeFragmentDoc}
`;

/**
 * __useGetBookedClassesAndSessionsByDateRangeQuery__
 *
 * To run a query within a React component, call `useGetBookedClassesAndSessionsByDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookedClassesAndSessionsByDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookedClassesAndSessionsByDateRangeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sessionDateFilter: // value for 'sessionDateFilter'
 *   },
 * });
 */
export function useGetBookedClassesAndSessionsByDateRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBookedClassesAndSessionsByDateRangeQuery,
    GetBookedClassesAndSessionsByDateRangeQueryVariables
  > &
    (
      | {
          variables: GetBookedClassesAndSessionsByDateRangeQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBookedClassesAndSessionsByDateRangeQuery,
    GetBookedClassesAndSessionsByDateRangeQueryVariables
  >(GetBookedClassesAndSessionsByDateRangeDocument, options);
}
export function useGetBookedClassesAndSessionsByDateRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBookedClassesAndSessionsByDateRangeQuery,
    GetBookedClassesAndSessionsByDateRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBookedClassesAndSessionsByDateRangeQuery,
    GetBookedClassesAndSessionsByDateRangeQueryVariables
  >(GetBookedClassesAndSessionsByDateRangeDocument, options);
}
export function useGetBookedClassesAndSessionsByDateRangeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetBookedClassesAndSessionsByDateRangeQuery,
        GetBookedClassesAndSessionsByDateRangeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetBookedClassesAndSessionsByDateRangeQuery,
    GetBookedClassesAndSessionsByDateRangeQueryVariables
  >(GetBookedClassesAndSessionsByDateRangeDocument, options);
}
export type GetBookedClassesAndSessionsByDateRangeQueryHookResult = ReturnType<
  typeof useGetBookedClassesAndSessionsByDateRangeQuery
>;
export type GetBookedClassesAndSessionsByDateRangeLazyQueryHookResult =
  ReturnType<typeof useGetBookedClassesAndSessionsByDateRangeLazyQuery>;
export type GetBookedClassesAndSessionsByDateRangeSuspenseQueryHookResult =
  ReturnType<typeof useGetBookedClassesAndSessionsByDateRangeSuspenseQuery>;
export type GetBookedClassesAndSessionsByDateRangeQueryResult =
  Apollo.QueryResult<
    GetBookedClassesAndSessionsByDateRangeQuery,
    GetBookedClassesAndSessionsByDateRangeQueryVariables
  >;
export const SelfDocument = gql`
  query self {
    self {
      ...UserDetails
    }
  }
  ${UserDetailsFragmentDoc}
`;

/**
 * __useSelfQuery__
 *
 * To run a query within a React component, call `useSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfQuery(
  baseOptions?: Apollo.QueryHookOptions<SelfQuery, SelfQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
}
export function useSelfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfQuery, SelfQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfQuery, SelfQueryVariables>(
    SelfDocument,
    options,
  );
}
export function useSelfSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SelfQuery, SelfQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SelfQuery, SelfQueryVariables>(
    SelfDocument,
    options,
  );
}
export type SelfQueryHookResult = ReturnType<typeof useSelfQuery>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfSuspenseQueryHookResult = ReturnType<
  typeof useSelfSuspenseQuery
>;
export type SelfQueryResult = Apollo.QueryResult<SelfQuery, SelfQueryVariables>;
export const IterableInitDocument = gql`
  query iterableInit {
    self {
      id
      email
      iterableToken(tokenType: web) {
        accessToken
      }
    }
  }
`;

/**
 * __useIterableInitQuery__
 *
 * To run a query within a React component, call `useIterableInitQuery` and pass it any options that fit your needs.
 * When your component renders, `useIterableInitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIterableInitQuery({
 *   variables: {
 *   },
 * });
 */
export function useIterableInitQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IterableInitQuery,
    IterableInitQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IterableInitQuery, IterableInitQueryVariables>(
    IterableInitDocument,
    options,
  );
}
export function useIterableInitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IterableInitQuery,
    IterableInitQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IterableInitQuery, IterableInitQueryVariables>(
    IterableInitDocument,
    options,
  );
}
export function useIterableInitSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        IterableInitQuery,
        IterableInitQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IterableInitQuery, IterableInitQueryVariables>(
    IterableInitDocument,
    options,
  );
}
export type IterableInitQueryHookResult = ReturnType<
  typeof useIterableInitQuery
>;
export type IterableInitLazyQueryHookResult = ReturnType<
  typeof useIterableInitLazyQuery
>;
export type IterableInitSuspenseQueryHookResult = ReturnType<
  typeof useIterableInitSuspenseQuery
>;
export type IterableInitQueryResult = Apollo.QueryResult<
  IterableInitQuery,
  IterableInitQueryVariables
>;
export const AuthHookDocument = gql`
  query authHook {
    self {
      id
      email
      registered
    }
  }
`;

/**
 * __useAuthHookQuery__
 *
 * To run a query within a React component, call `useAuthHookQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthHookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthHookQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthHookQuery(
  baseOptions?: Apollo.QueryHookOptions<AuthHookQuery, AuthHookQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuthHookQuery, AuthHookQueryVariables>(
    AuthHookDocument,
    options,
  );
}
export function useAuthHookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthHookQuery,
    AuthHookQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuthHookQuery, AuthHookQueryVariables>(
    AuthHookDocument,
    options,
  );
}
export function useAuthHookSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AuthHookQuery, AuthHookQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuthHookQuery, AuthHookQueryVariables>(
    AuthHookDocument,
    options,
  );
}
export type AuthHookQueryHookResult = ReturnType<typeof useAuthHookQuery>;
export type AuthHookLazyQueryHookResult = ReturnType<
  typeof useAuthHookLazyQuery
>;
export type AuthHookSuspenseQueryHookResult = ReturnType<
  typeof useAuthHookSuspenseQuery
>;
export type AuthHookQueryResult = Apollo.QueryResult<
  AuthHookQuery,
  AuthHookQueryVariables
>;
export const AuthenticatedLayoutDocument = gql`
  query authenticatedLayout {
    self {
      id
      email
    }
  }
`;

/**
 * __useAuthenticatedLayoutQuery__
 *
 * To run a query within a React component, call `useAuthenticatedLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticatedLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticatedLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthenticatedLayoutQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthenticatedLayoutQuery,
    AuthenticatedLayoutQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AuthenticatedLayoutQuery,
    AuthenticatedLayoutQueryVariables
  >(AuthenticatedLayoutDocument, options);
}
export function useAuthenticatedLayoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthenticatedLayoutQuery,
    AuthenticatedLayoutQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AuthenticatedLayoutQuery,
    AuthenticatedLayoutQueryVariables
  >(AuthenticatedLayoutDocument, options);
}
export function useAuthenticatedLayoutSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AuthenticatedLayoutQuery,
        AuthenticatedLayoutQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AuthenticatedLayoutQuery,
    AuthenticatedLayoutQueryVariables
  >(AuthenticatedLayoutDocument, options);
}
export type AuthenticatedLayoutQueryHookResult = ReturnType<
  typeof useAuthenticatedLayoutQuery
>;
export type AuthenticatedLayoutLazyQueryHookResult = ReturnType<
  typeof useAuthenticatedLayoutLazyQuery
>;
export type AuthenticatedLayoutSuspenseQueryHookResult = ReturnType<
  typeof useAuthenticatedLayoutSuspenseQuery
>;
export type AuthenticatedLayoutQueryResult = Apollo.QueryResult<
  AuthenticatedLayoutQuery,
  AuthenticatedLayoutQueryVariables
>;
export const MixpanelHookDocument = gql`
  query mixpanelHook {
    self {
      id
      email
      fitCoach {
        id
      }
    }
  }
`;

/**
 * __useMixpanelHookQuery__
 *
 * To run a query within a React component, call `useMixpanelHookQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixpanelHookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixpanelHookQuery({
 *   variables: {
 *   },
 * });
 */
export function useMixpanelHookQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MixpanelHookQuery,
    MixpanelHookQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MixpanelHookQuery, MixpanelHookQueryVariables>(
    MixpanelHookDocument,
    options,
  );
}
export function useMixpanelHookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MixpanelHookQuery,
    MixpanelHookQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MixpanelHookQuery, MixpanelHookQueryVariables>(
    MixpanelHookDocument,
    options,
  );
}
export function useMixpanelHookSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MixpanelHookQuery,
        MixpanelHookQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MixpanelHookQuery, MixpanelHookQueryVariables>(
    MixpanelHookDocument,
    options,
  );
}
export type MixpanelHookQueryHookResult = ReturnType<
  typeof useMixpanelHookQuery
>;
export type MixpanelHookLazyQueryHookResult = ReturnType<
  typeof useMixpanelHookLazyQuery
>;
export type MixpanelHookSuspenseQueryHookResult = ReturnType<
  typeof useMixpanelHookSuspenseQuery
>;
export type MixpanelHookQueryResult = Apollo.QueryResult<
  MixpanelHookQuery,
  MixpanelHookQueryVariables
>;
export const UpdateLastActivityDocument = gql`
  mutation updateLastActivity {
    updateLastActivity(app: heart, platform: web) {
      user {
        id
      }
    }
  }
`;
export type UpdateLastActivityMutationFn = Apollo.MutationFunction<
  UpdateLastActivityMutation,
  UpdateLastActivityMutationVariables
>;

/**
 * __useUpdateLastActivityMutation__
 *
 * To run a mutation, you first call `useUpdateLastActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastActivityMutation, { data, loading, error }] = useUpdateLastActivityMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateLastActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLastActivityMutation,
    UpdateLastActivityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLastActivityMutation,
    UpdateLastActivityMutationVariables
  >(UpdateLastActivityDocument, options);
}
export type UpdateLastActivityMutationHookResult = ReturnType<
  typeof useUpdateLastActivityMutation
>;
export type UpdateLastActivityMutationResult =
  Apollo.MutationResult<UpdateLastActivityMutation>;
export type UpdateLastActivityMutationOptions = Apollo.BaseMutationOptions<
  UpdateLastActivityMutation,
  UpdateLastActivityMutationVariables
>;
export const GetUserSavedVideosDocument = gql`
  query getUserSavedVideos($connectionInput: ConnectionInput) {
    self {
      savedContent(
        input: $connectionInput
        filter: { component: "view-ondemand-class" }
      ) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...OnDemandVideoPreview
        }
      }
    }
  }
  ${OnDemandVideoPreviewFragmentDoc}
`;

/**
 * __useGetUserSavedVideosQuery__
 *
 * To run a query within a React component, call `useGetUserSavedVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSavedVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSavedVideosQuery({
 *   variables: {
 *      connectionInput: // value for 'connectionInput'
 *   },
 * });
 */
export function useGetUserSavedVideosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserSavedVideosQuery,
    GetUserSavedVideosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserSavedVideosQuery,
    GetUserSavedVideosQueryVariables
  >(GetUserSavedVideosDocument, options);
}
export function useGetUserSavedVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserSavedVideosQuery,
    GetUserSavedVideosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserSavedVideosQuery,
    GetUserSavedVideosQueryVariables
  >(GetUserSavedVideosDocument, options);
}
export function useGetUserSavedVideosSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUserSavedVideosQuery,
        GetUserSavedVideosQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserSavedVideosQuery,
    GetUserSavedVideosQueryVariables
  >(GetUserSavedVideosDocument, options);
}
export type GetUserSavedVideosQueryHookResult = ReturnType<
  typeof useGetUserSavedVideosQuery
>;
export type GetUserSavedVideosLazyQueryHookResult = ReturnType<
  typeof useGetUserSavedVideosLazyQuery
>;
export type GetUserSavedVideosSuspenseQueryHookResult = ReturnType<
  typeof useGetUserSavedVideosSuspenseQuery
>;
export type GetUserSavedVideosQueryResult = Apollo.QueryResult<
  GetUserSavedVideosQuery,
  GetUserSavedVideosQueryVariables
>;
